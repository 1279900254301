import React, {useEffect, useState} from 'react';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import fetchAPI from '../../Fetch';
import {Button, Row, Select} from 'antd';
import {getAllOrders, getVendorOrders, getVendorOrdersByVendorId, Order, SubOrders} from '../../Order';
import * as XLSX from 'xlsx';
import {getProductByID} from '../../Products/Products';
import {getUserById} from '../../User';
import {useTranslation} from "react-i18next";

interface DownloadButtonProps {
    id_vendor: number;
    title: string;
    filterFunction: (order: Order, subOrder: SubOrders) => boolean;
    filename: string;
}

interface DownloadAllOrdersProps {
    selectedMonth: number;
    vendor_id: number;
}

const colorProductBtn = "#7ED957";
const colorHeaderText = "#09154C";

function DownloadProducts() {
    const {t} = useTranslation();

    const [selectedFileType, setSelectedFileType] = useState('xls');

    const downloadProduct = async () => {
        const fileType = selectedFileType;
        const toastId = toast.info(t('Téléchargement en cours...'), {autoClose: false});

        try {
            const filename = await fetchAPI(`upload/export/${fileType}`, "GET", true, true);
            const link = `https://${process.env.REACT_APP_API_URL}`+  `/media/public/spreadsheet/${filename.response.filename}`;
            const response = await fetch(link);
            const blob = await response.blob();

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = filename.response.filename;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);

            toast.update(toastId, {render: t('Téléchargement terminé !'), type: toast.TYPE.SUCCESS, autoClose: 5000});
        } catch (error) {
            toast.update(toastId, {
                render: t('Erreur lors du téléchargement.'),
                type: toast.TYPE.ERROR,
                autoClose: 5000
            });
        }
    };

    return (
        <div>
            <Row>
                <h3 style={{margin: "auto 5px"}}>{t('Télécharger mes produits')}</h3>
                <Select defaultValue="xls" onChange={setSelectedFileType}>
                    <Select.Option value="xls">Format XLS</Select.Option>
                    <Select.Option value="csv">Format CSV</Select.Option>
                </Select>
                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={downloadProduct}>
                    {t('Télécharger')}
                </Button>
                <ToastContainer/>
            </Row>
        </div>
    );
}

const downloadOrder = async (id_vendor: number, filterFunction: (order: Order, subOrder: SubOrders) => boolean, filename: string) => {


    const toastId = toast.info('Téléchargement en cours...', {autoClose: false});

    try {
        const orders = await getVendorOrdersByVendorId(id_vendor);
        const wsData = [];
        wsData.push([
            "DATE", "N° Commande", "NOM Client", "EMAIL Client",
            "REFERENCE", "DESIGNATION", "QUANTITE", "PRIX VENTE U HT",
            "PRIX DE VENTE TOTAL HT","PRIX DE VENTE TOTAL TTC", "COMMISSION HT", "TVA 20% SUR LA COMMISSION",
            "COMMISSION TTC", "SOLDE RECUPERABLE HT", "SOLDE RECUPERABLE TTC", "STATUT"
        ]);

        for (const order of orders) {

            const client = await getUserById(order.buyer_id);
            if (!client) continue;

            for (const subOrder of order.sub_orders) {
                if (filterFunction(order, subOrder)) {
                    const product = await getProductByID(subOrder.product_id);
                    if (!product || id_vendor!=product.vendor_id) continue;
                    const actualPrice = subOrder.product_price_HT / subOrder.quantity;
                    let commissionHT
                    let tvaCommission
                    let commissionTTC
                    let recuperableht
                    let recuperablettc
                    if (product.has_tva) {
                        if(order.creation_date > 1704063601){
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.15);
                        } else {
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.1);
                        }
                        // commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.1);
                        tvaCommission = (commissionHT * 1.2) - commissionHT;
                        commissionTTC = commissionHT + tvaCommission;
                        recuperableht = (actualPrice * subOrder.quantity) - commissionHT;
                        recuperablettc = recuperableht * 1.2
                    } else {
                        if(order.creation_date > 1704063601){
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.15);
                            tvaCommission = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.18) - commissionHT;
                            commissionTTC = commissionHT + tvaCommission;
                            recuperableht = (actualPrice * subOrder.quantity) / 1.18;
                        } else {
                            commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.1);
                            tvaCommission = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.12) - commissionHT;
                            commissionTTC = commissionHT + tvaCommission;
                            recuperableht = (actualPrice * subOrder.quantity) / 1.12;
                        }
                        // commissionHT = (actualPrice * subOrder.quantity) - ((actualPrice * subOrder.quantity) / 1.1);

                        recuperablettc = recuperableht
                        console.log({
                            commissionHT: commissionHT,
                            tvaCommission: tvaCommission,
                            commissionTTC: commissionTTC,
                            recuperableht: recuperableht,
                            recuperablettc: recuperablettc
                        })
                    }
                    const timestamp = order.creation_date * 1000;
                    const formattedDate = new Date(timestamp).toLocaleDateString('fr-FR', {
                        day: 'numeric',
                        month: 'short',
                        year: 'numeric',
                    });

                    const formattedTime = new Date(timestamp).toLocaleTimeString('fr-FR', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                    });

                    const formattedDateTime = `${formattedDate}, ${formattedTime}`;

                    const row = [
                        formattedDateTime,
                        order.id,
                        `${client.firstname} ${client.lastname}`,
                        client.user_email,
                        product.product_sku || '',
                        product.product_name,
                        subOrder.quantity,
                        parseFloat(actualPrice.toFixed(3)),
                        parseFloat((actualPrice * subOrder.quantity).toFixed(3)),
                        parseFloat(product.has_tva ? ((actualPrice * subOrder.quantity)*1.2).toFixed(3) : ((actualPrice * subOrder.quantity)).toFixed(3)),
                        parseFloat(commissionHT.toFixed(3)),
                        parseFloat(tvaCommission.toFixed(3)),
                        parseFloat(commissionTTC.toFixed(3)),
                        parseFloat(recuperableht.toFixed(3)),
                        parseFloat(recuperablettc.toFixed(3)),
                        subOrder.state_id === 5 ? "Remboursée" : "Terminée",
                    ];

                    wsData.push(row);
                }
            }
        }

        const ws = XLSX.utils.aoa_to_sheet(wsData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Orders");

        XLSX.writeFile(wb, filename);

        toast.update(toastId, {render: 'Téléchargement terminé !', type: toast.TYPE.SUCCESS, autoClose: 5000});
    } catch (error) {
        console.log(error);
        toast.update(toastId, {
            render: 'Erreur lors du téléchargement.',
            type: toast.TYPE.ERROR,
            autoClose: 5000
        });
    }
};

const DownloadOrdersNotRecupered = () => {
    const {t} = useTranslation();

    return (
        <DownloadButton
            title={t('Télécharger mes commandes solde récupérable')}
            filterFunction={(order, subOrder) =>
                order.MangoPayOrderTransaction[0] &&
                order.MangoPayOrderTransaction[0].done &&
                !order.MangoPayOrderTransaction[0].recovered &&
                (subOrder.state_id === 2 || subOrder.state_id === 3 || subOrder.state_id === 5 || subOrder.state_id === 4)
            }
            filename="recap_commandes_solde_récupérable.xlsx" id_vendor={localStorage.getItem('user_id') as unknown as number}
        />
    );
}


const DownloadOrders = () => {
    const {t} = useTranslation();

    return (
        <DownloadButton
            title={t('Télécharger mes commandes solde récupéré')}
            filterFunction={(order, subOrder) =>
                order.MangoPayOrderTransaction[0] &&
                order.MangoPayOrderTransaction[0].done &&
                order.MangoPayOrderTransaction[0].recovered &&
                (subOrder.state_id === 2 || subOrder.state_id === 3 || subOrder.state_id === 5  || subOrder.state_id === 4)
            }
            filename="recap_commandes_solde_récupéré.xlsx" id_vendor={localStorage.getItem('user_id') as unknown as number}
        />
    );
}


// Toutes les Commandes pour 1732 non recupéré donc récupérable
const DownloadSpecificOrders = () => {
    const handleDownload = () => {
        // Replace the URL below with the actual URL of the file you want to download
        const fileUrl = `https://${process.env.WEBSITE_URL}`+ '/recap_commandes.xlsx';

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = fileUrl;
        link.target = '_blank'; // Open the link in a new tab
        link.download = 'recap_commandes.xlsx'; // Specify the desired file name

        // Append the link to the document and trigger a click event
        document.body.appendChild(link);
        link.click();

        // Remove the link from the document
        document.body.removeChild(link);
    };

    return (
        <Button  style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={handleDownload}>
            Télécharger mes commandes solde récupérable
        </Button>
    );
};



const DownloadAllOrders = () => {
    const {t} = useTranslation();

    return (
        <DownloadButton title={t('Télécharger les commandes début mois précédent à fin de mois précédent')}
                        filterFunction={(order: { creation_date: number; }) => {
                            const currentDate = new Date();
                            const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
                            const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
                            const orderDate = new Date(order.creation_date * 1000);
                            return orderDate >= firstDayOfPreviousMonth && orderDate <= lastDayOfPreviousMonth;
                        }} filename="recap_orders_not_recovered.xlsx"
                        id_vendor={localStorage.getItem('user_id') as unknown as number}/>
    );
}


const DownloadAllOrdersByDate: React.FC<DownloadAllOrdersProps> = ({
                                                                       selectedMonth = new Date().getMonth(),
                                                                       vendor_id
                                                                   }) => {
    console.log(selectedMonth, new Date().getMonth() + 1, selectedMonth > new Date().getMonth() +1 ? new Date().getFullYear() - 1 : new Date().getFullYear())
    const firstDayOfMonth = new Date(selectedMonth > new Date().getMonth() + 1 ? new Date().getFullYear() - 1 : new Date().getFullYear(), selectedMonth - 1, 1);
    const lastDayOfMonth = new Date(selectedMonth > new Date().getMonth() + 1 ? new Date().getFullYear() - 1 : new Date().getFullYear(), selectedMonth, 1);
    const [vendor_name, setVendorName] = useState("");
    const [vendor_email, setVendorEmail] = useState("");
    const [data_fetched, setDataFetched] = useState(false);
    const {t} = useTranslation();

    useEffect(() => {
        async function fetchData() {
            const vendor = await getUserById(vendor_id);
            if (vendor) {
                setVendorEmail(vendor.user_email);
                setVendorName(vendor.firstname + " " + vendor.lastname);
                setDataFetched(true)
            }

        }

        if (!data_fetched)
            fetchData();
        setDataFetched(true);

    }, [vendor_name, vendor_email, data_fetched, vendor_id]);

    return (
        <DownloadButton
            title={'Télécharger les commandes pour le mois '}
            filterFunction={(order: { creation_date: number; }, subOrder: SubOrders) => {
                const orderDate = new Date(order.creation_date * 1000);
                return (
                    orderDate >= firstDayOfMonth &&
                    orderDate <= lastDayOfMonth &&
                    (subOrder.state_id === 2 || subOrder.state_id === 3 || subOrder.state_id === 5)
                );
            }}
            filename={`recap_orders_${selectedMonth}_${vendor_email}_${vendor_name}_${vendor_id}.xlsx`}
            id_vendor={vendor_id}
        />

    );
};


const DownloadButton: React.FC<DownloadButtonProps> = ({id_vendor, title, filterFunction, filename}) => {
    return (
        <div>
            <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                    onClick={() => downloadOrder(id_vendor, filterFunction, filename)}>
                {title}
            </Button>
            <ToastContainer/>
        </div>
    );
};

export {
    DownloadProducts,
    DownloadOrders,
    DownloadOrdersNotRecupered,
    DownloadAllOrders,
    DownloadButton,
    downloadOrder,
    DownloadAllOrdersByDate,
    DownloadSpecificOrders
};
