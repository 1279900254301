import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Space,
    Table,
    Pagination,
    Form,
    Upload,
    UploadProps, message
} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined, InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {
    deleteProduct,
    downloadProduct, getProductsAllPublished, getProductsLastProductsPublished,
    GlobalProduct, telechargerFichierExemple,
} from "../../Products/Products";
import {UploadFile} from "antd/es/upload/interface";
import {CreateProduct} from '../../../components/Dashboard'
import {DownloadProducts} from "../Vendor/downloadProducts";
import {getUserById} from "../../User";
import ModifyProductAdmin from "./ModifyProduct";


interface dataSourceInterface {
    key: string,
    id: string,
    productName: string,
    sku: string,
    stock: string,
    creationDate: string,
    modificationDate: string,
    quantity: number
    price: number,
    promoPrice: number,
    categories: string,
    vendorName: string,
    vendorEmail: string,
    date: string,
}

export default function ProductsAdmin() {

    const handleUploadChange = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} a été téléchargé avec succès`);
        } else if (info.file.status === 'error') {
            let errorMessage = "Une erreur est survenue lors du téléchargement";
            if (info.file.response && info.file.response.message) {
                errorMessage = info.file.response.message;
            }
            message.error(errorMessage, 10);
        }
    };
    const [showModifyProduct, setShowModifyProduct] = useState(false);
    const [showProduct, setShowProduct] = useState(true);
    const [slug, setSlug] = useState("");

    // Step 2: Function to toggle the state when the button is clicked
    const handleShowModifyProduct = (slug_var: string) => {
        setShowModifyProduct(!showModifyProduct);
        setSlug(slug_var)
        setShowProduct(false)
    };

    const [fileList2, setFileList2] = useState<UploadFile[]>([]);
    const onChange2: UploadProps['onChange'] = ({fileList: newFileList2}) => {
        setFileList2(newFileList2);
    };
    const config = {
        // ContentType: 'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    const data = {
        name: "",
        description: ""
    }
    const [searchString, setSearchString] = useState("");
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    useEffect(() => {
        const fetchUserInfos = async () => {
            const products = await getProductsLastProductsPublished();
            setProductInfos(products)
            console.log(products)
            console.log(products)
            if (products.length === 0) {
                console.log("No PRODUCT data found");
                let btn_telecharger_produits = document.getElementById("dl_products");
                let btn_edit_massif_products = document.getElementById("edit_massif_products");
                if (btn_telecharger_produits && btn_edit_massif_products) {
                    btn_telecharger_produits.style.display = "none";
                    btn_edit_massif_products.style.display = "none";
                }
            }
            try {
                const vendorInfoCache: Record<string, Promise<any>> = {};

                const mappedOptions = products.map(async (product: any) => {
                    const vendorId = product.product.vendor_id;
                    let vendorInfoPromise = vendorInfoCache[vendorId];

                    // Si les informations pour le vendor_id ne sont pas dans le cache, appelez getUserById
                    if (!vendorInfoPromise) {
                        vendorInfoPromise = getUserById(vendorId);
                        vendorInfoCache[vendorId] = vendorInfoPromise;
                    }

                    const vendorInfo = await vendorInfoPromise;

                    return {
                        key: product.product.id as unknown as string,
                        productName: product.product.product_name,
                        sku: product.product.product_sku,
                        date: new Date(product.product.creation_date * 1000).toDateString(),
                        categories: '',
                        id: product.product.id as unknown as string,
                        price: product.product.price?.toFixed(2) as unknown as number,
                        creationDate: new Date(product.product.creation_date * 1000).toDateString(),
                        modificationDate: new Date(product.product.modification_date * 1000).toDateString(),
                        promoPrice: product.product.price_promo?.toFixed(2) as unknown as number,
                        stock: product.product.quantity > 0 ? "Stock" : "Hors stock",
                        quantity: product.product.quantity,
                        vendorEmail: vendorInfo ? vendorInfo.user_email : "",
                        vendorName: vendorInfo ? vendorInfo.lastname : "",
                        slug: product.product.slug,
                    } as dataSourceInterface;
                });
                const mappedOptionsWithData = await Promise.all(mappedOptions);
                setOptions(mappedOptionsWithData);
            } catch (error) {
                console.log("No user data found");
            }
            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };

    const dataSource = options.filter(option =>
        option.productName?.toLowerCase().includes(searchString?.toLowerCase()) ||
        option.sku?.toLowerCase().includes(searchString?.toLowerCase())
    );


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Nom du Produit',
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Date de création',
            dataIndex: 'creationDate',
            key: 'creationDate',
            width: '15%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.creationDate);
                const dateB = new Date(b.creationDate);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Date de modification',
            dataIndex: 'modificationDate',
            key: 'modificationDate',
            width: '15%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.modificationDate);
                const dateB = new Date(b.modificationDate);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Quantité',
            dataIndex: 'quantity',
            key: 'quantity',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Email du vendeur',
            dataIndex: 'vendorEmail',
            key: 'vendorEmail',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Nom du vendeur',
            dataIndex: 'vendorName',
            key: 'vendorName',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Stock',
            dataIndex: 'stock',
            key: 'stock',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.stock === 'Stock') {
                    return <span style={{
                        color: '#50CD89',
                        backgroundColor: '#DCF2E6',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },
        {
            title: 'Prix de Cession (HT)',
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Prix Promo de Cession (HT)',
            dataIndex: 'promoPrice',
            key: 'promoPrice',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        // {
        //     title: 'Catégories',
        //     dataIndex: 'categories',
        //     key: 'categories',
        //     width: '15%',
        //     render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        // },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => {
                        handleShowModifyProduct(record.slug)
                    }}/>
                    <Button style={{margin: '5px'}} icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => {
                        deleteProduct(record.id)
                    }}/>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        // Add your hover styles here, for example:
        backgroundColor: '#000000',
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div>
            {showModifyProduct ?
                <><Button onClick={() => {
                    setShowModifyProduct(false);
                    setShowProduct(true)
                }}>Retourner sur les produits</Button><ModifyProductAdmin slug={slug}/></>
                : <></>}
            {dataFetched && showProduct ? (
                    <div>
                        {windowWidth < 768 ? (
                            <div
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}>
                                <h1 style={{fontSize: '24px'}}>Produits</h1>
                                {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                                <div>
                                    <h3 style={{
                                        marginTop: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        textAlign: 'center'
                                    }}>Pour garantir une expérience agréable lors de l'utilisation de nos services, nous ne
                                        pouvons
                                        afficher ces informations sur votre appareil.</h3>
                                    <h3 style={{
                                        marginTop: '15px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        textAlign: 'center'
                                    }}>Nous nous excusons pour la gêne occasionnée.</h3>
                                </div>
                            </div>
                        ) : windowWidth >= 768 && windowWidth < 1100 ? (
                            <div
                                style={{
                                    margin: '0px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    background: "#F3F5F6",
                                    borderRadius: '15px'
                                }}>
                                <div style={{color: colorHeaderText, backgroundColor: '#F3F5F6'}}>
                                    <div style={{margin: '15px'}}>
                                        <h1 style={{fontSize: '24px'}}>Produits</h1>
                                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                                    </div>
                                    <div style={{
                                        backgroundColor: '#FFFFFF',
                                        border: '1px solid #FFFFFF',
                                        borderRadius: '5px',
                                        width: '95%',
                                        margin: '20px auto'
                                    }}>
                                        <div style={{padding: '20px'}}>
                                            <Row>
                                                <Col flex={2}>
                                                    <Space.Compact style={{width: '95%'}}>
                                                        <Input
                                                            name="search"
                                                            placeholder="Rechercher un produit..."
                                                            style={{width: '100%'}}
                                                            value={searchString}
                                                            onChange={onSearchInputChange}
                                                        />
                                                        <Button type="primary" style={{
                                                            color: colorHeaderText,
                                                            backgroundColor:
                                                            colorHearderSearch,
                                                            border: '1px solid' + colorHeaderVerticalBar,
                                                        }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                                    </Space.Compact>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div style={{marginTop: '10px'}}>
                                            <style>
                                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                                            </style>
                                            <Table
                                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                                columns={columns}
                                                bordered
                                                pagination={false}
                                                className="custom-table"
                                                style={{margin: '0 20px'}}
                                                scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                                            />
                                        </div>
                                        <div style={{margin: '20px'}}>
                                            <style>
                                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                          
                          .ant-space-item{
                          width:100% !important}
                        `}
                                            </style>
                                            <Pagination
                                                total={totalItems}
                                                current={currentPage}
                                                showSizeChanger
                                                onShowSizeChange={handleItemsPerPageChange}
                                                onChange={handlePageChange}
                                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                                pageSize={itemsPerPage}
                                                defaultCurrent={1}
                                                style={customPaginationStyle}
                                            />
                                        </div>
                                        <div style={{padding: '20px'}}>
                                            <Row>
                                                <Col flex={2} style={{margin: 'auto 0'}}>
                                                    {/*<Space style={{borderWidth: 15,borderColor:'#fff',width:'55%'}}>*/}
                                                    <Space.Compact style={{width: '75%'}}>
                                                        <Input
                                                            name="search"
                                                            placeholder="Rechercher un produit..."
                                                            value={searchString}
                                                            onChange={onSearchInputChange}
                                                        />
                                                        <Button type="primary" style={{
                                                            color: colorHeaderText,
                                                            backgroundColor:
                                                            colorHearderSearch,
                                                            border: '1px solid' + colorHeaderVerticalBar,
                                                        }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                                    </Space.Compact>
                                                    {/*</Space>*/}
                                                    <Button
                                                        style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                        onClick={() => document.getElementById("createProduct")?.click()}>
                                                        Ajouter un produit
                                                    </Button>
                                                </Col>

                                                <Col flex={1}>
                                                    <Button
                                                        style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                        onClick={() => {
                                                            telechargerFichierExemple(`https://${process.env.WEBSITE_URL}`+ "/uploads/Files/Fichier_Exemple_Import_massif_nouveaux_produits.xlsx", 'fichier_exemple_ajout_massif.xlsx');
                                                        }}>
                                                        Fichier exemple import massif de produits
                                                    </Button>
                                                </Col>
                                                <Col flex={1} style={{display: 'contents'}}>
                                                    <Form>
                                                        <Upload
                                                            name="file"
                                                            action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                            // listType="text"
                                                            // fileList={fileList2}
                                                            // onChange={onChange2}
                                                            headers={config}
                                                            method={"POST"}
                                                            // onPreview={onPreview}
                                                            // data={data}
                                                            onChange={handleUploadChange}
                                                            maxCount={1}
                                                            style={{marginBottom: '10px', width: '95%'}}
                                                        >
                                                            <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Ajouter massivement des
                                                                produits</Button>
                                                        </Upload>
                                                    </Form>
                                                </Col>

                                            </Row>
                                            <Row style={{marginTop: '10px'}}>
                                                <Col flex={1} style={{marginLeft: '50%'}} id="dl_products">
                                                    {/*<Button id="dl_products" style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={() => {downloadProduct()}}>*/}
                                                    {/*    Télécharger mes produits*/}
                                                    {/*</Button>*/}
                                                    <DownloadProducts/>
                                                </Col>
                                                {/*<Space style={{borderWidth: 15,borderColor:'#fff'}}>*/}
                                                <Col flex={1} style={{display: 'contents'}} id="edit_massif_products">
                                                    <Form>
                                                        <Upload
                                                            name="file"
                                                            action={`https://${process.env.REACT_APP_API_URL}` + "upload/csv-products"}
                                                            // listType="text"
                                                            // fileList={fileList2}
                                                            // onChange={onChange2}
                                                            headers={config}
                                                            method={"PUT"}
                                                            // onPreview={onPreview}
                                                            // data={data}
                                                            onChange={handleUploadChange}
                                                            maxCount={1}
                                                            style={{marginBottom: '10px', width: '95%'}}
                                                        >
                                                            <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Modification massive des
                                                                produits</Button>
                                                        </Upload>
                                                    </Form>
                                                </Col>
                                                {/*</Space>*/}

                                            </Row>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div style={{color: colorHeaderText, backgroundColor: '#F3F5F6'}}>
                                <div style={{margin: '15px'}}>
                                    <h1 style={{fontSize: '24px'}}>Produits</h1>
                                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                                </div>
                                <div style={{
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #FFFFFF',
                                    borderRadius: '5px',
                                    width: '95%',
                                    margin: '20px auto'
                                }}>

                                    <div style={{padding: '20px'}}>
                                        <Row>
                                            <Col flex={2} style={{margin: 'auto 0'}}>
                                                {/*<Space style={{borderWidth: 15,borderColor:'#fff',width:'55%'}}>*/}
                                                <Space.Compact style={{width: '75%'}}>
                                                    <Input
                                                        name="search"
                                                        placeholder="Rechercher un produit..."
                                                        value={searchString}
                                                        onChange={onSearchInputChange}
                                                    />
                                                    <Button type="primary" style={{
                                                        color: colorHeaderText, backgroundColor:
                                                        colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                                    }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                                </Space.Compact>
                                                {/*</Space>*/}
                                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                        onClick={() => document.getElementById("createProduct")?.click()}>
                                                    Ajouter un produit
                                                </Button>
                                            </Col>

                                            <Col flex={1}>
                                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                        onClick={() => {
                                                            telechargerFichierExemple(`https://${process.env.WEBSITE_URL}`+ "/uploads/Files/Fichier_Exemple_Import_massif_nouveaux_produits.xlsx", 'fichier_exemple_ajout_massif.xlsx');
                                                        }}>
                                                    Fichier exemple import massif de produits
                                                </Button>
                                            </Col>
                                            <Col flex={1} style={{display: 'contents'}}>
                                                <Form>
                                                    <Upload
                                                        name="file"
                                                        action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                        // listType="text"
                                                        // fileList={fileList2}
                                                        // onChange={onChange2}
                                                        headers={config}
                                                        method={"POST"}
                                                        // onPreview={onPreview}
                                                        // data={data}
                                                        onChange={handleUploadChange}
                                                        maxCount={1}
                                                        style={{marginBottom: '10px', width: '95%'}}
                                                    >
                                                        <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Ajouter massivement des
                                                            produits</Button>
                                                    </Upload>
                                                </Form>
                                            </Col>

                                        </Row>
                                        <Row style={{marginTop: '10px'}}>
                                            <Col flex={1} style={{marginLeft: '50%'}} id="dl_products">
                                                {/*<Button id="dl_products" style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={() => {downloadProduct()}}>*/}
                                                {/*    Télécharger mes produits*/}
                                                {/*</Button>*/}
                                                <DownloadProducts/>
                                            </Col>
                                            {/*<Space style={{borderWidth: 15,borderColor:'#fff'}}>*/}
                                            <Col flex={1} style={{display: 'contents'}} id="edit_massif_products">
                                                <Form>
                                                    <Upload
                                                        name="file"
                                                        action={`https://${process.env.REACT_APP_API_URL}` + "upload/csv-products"}
                                                        // listType="text"
                                                        // fileList={fileList2}
                                                        // onChange={onChange2}
                                                        headers={config}
                                                        method={"PUT"}
                                                        // onPreview={onPreview}
                                                        // data={data}
                                                        onChange={handleUploadChange}
                                                        maxCount={1}
                                                        style={{marginBottom: '10px', width: '95%'}}
                                                    >
                                                        <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>Modification massive des
                                                            produits</Button>
                                                    </Upload>
                                                </Form>
                                            </Col>
                                            {/*</Space>*/}

                                        </Row>
                                    </div>

                                    <div style={{marginTop: '10px'}}>
                                        <style>
                                            {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                                        </style>
                                        <Table
                                            dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                            columns={columns}
                                            bordered
                                            pagination={false}
                                            className="custom-table"
                                            style={{margin: '0 20px'}}
                                            scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                                        />
                                    </div>
                                    <div style={{margin: '20px'}}>
                                        <style>
                                            {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                                        </style>
                                        <Pagination
                                            total={totalItems}
                                            current={currentPage}
                                            showSizeChanger
                                            onShowSizeChange={handleItemsPerPageChange}
                                            onChange={handlePageChange}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                            pageSize={itemsPerPage}
                                            defaultCurrent={1}
                                            style={customPaginationStyle}
                                        />
                                    </div>
                                </div>
                            </div>
                        )
                        }
                    </div>

                )
                : <></>
            }

        </div>
    );
}