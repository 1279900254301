// CacheContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from "react";
import {getProductsAllPublished, getProductsAllPublishedNoFilters, ProductInterface} from "../Products/Products";
import { getDataFetched, setDataFetched } from "./cacheStatus";

export interface Product {
    image: string;
    product: ProductInterface;
}

interface CacheContextProps {
    data: Product[] | null;
    fetchData: () => void;
}

const CacheContext = createContext<CacheContextProps | undefined>(undefined);

interface CacheProviderProps {
    children: ReactNode;
}

export const CacheProvider: React.FC<CacheProviderProps> = ({ children }) => {
    const [data, setData] = useState<Product[] | null>(null);

    const fetchData = async () => {
        try {
            if (!getDataFetched()) {
                // Essayez d'abord de récupérer les données du cache local
                const cachedData = localStorage.getItem("cachedData");

                if (cachedData) {
                    setData(JSON.parse(cachedData));
                } else {
                    // Si les données ne sont pas en cache, effectuez l'appel API
                    const products = await getProductsAllPublishedNoFilters(100);
                    // Formatez les prix ici avec toFixed(2)
                    const filteredProducts = products.filter((product: { product: any; }) => product.product.backorder === false);


                    const formattedProducts = filteredProducts.map((product: { product: { price: number; price_promo: number; has_tva:boolean } }) => ({
                        ...product,
                        product: {
                            ...product.product,
                            price: product.product.has_tva ? (product.product.price * 1.15 * 1.2).toFixed(2) : (product.product.price*1.18).toFixed(2),
                            price_promo: product.product.price_promo ? product.product.has_tva ?  (product.product.price_promo * 1.15 * 1.2).toFixed(2) : (product.product.price_promo*1.18).toFixed(2) : null,
                        },
                    }));


                    setData(formattedProducts);

                    // Enregistrez les nouvelles données dans le cache local
                    localStorage.setItem("cachedData", JSON.stringify(formattedProducts));

                    // Mettez à jour le statut du fetch
                    setDataFetched(true);
                }
            }
        } catch (error) {
            console.log("Error fetching or caching data:", error);
        }
    };

    useEffect(() => {
        // Fetch data only if not in the cache
        if (!data) {
            fetchData();
        }
    }, [data]);

    return (
        <CacheContext.Provider value={{ data, fetchData }}>
            {children}
        </CacheContext.Provider>
    );
};

export const useCache = () => {
    const context = useContext(CacheContext);
    if (!context) {
        throw new Error("useCache must be used within a CacheProvider");
    }
    return context;
};

export const shuffleArray = (array: any[]) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
};
