import React, {useEffect, useState} from 'react';
import {LoadingOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Upload} from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import TextArea from "antd/es/input/TextArea";
import {createProduct} from "../../Products/Products";
import {ToastContainer} from "react-toastify";


export default function CreateProductAdmin() {
    localStorage.removeItem('createProduct'); // l'enlever après utilisation
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    const handleChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj as RcFile, (url) => {
                setLoading(false);
                setImageUrl(url);
                // uploadMedia(url)
            });
        }
    };

    const config = {
        // ContentType: 'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const data = {
        name: "",
        description: ""
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> : <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
            <div style={{marginTop: 8}}>Upload</div>
        </div>
    );

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileList2, setFileList2] = useState<UploadFile[]>([]);

    const onChange: UploadProps['onChange'] = ({fileList: newFileList}) => {
        setFileList(newFileList);
    };
    const onChange2: UploadProps['onChange'] = ({fileList: newFileList2}) => {
        setFileList2(newFileList2);
    };


    function setPrice(){
        let price = form.getFieldValue("price");
        let price_promo = form.getFieldValue("price_promo");
        let state = form.getFieldValue("state");
        console.log(price)
        console.log(state)
        if (state==="neuf"){
            price=price*1.1;
            price_promo=price_promo*1.1;
            price=price*1.2;
            price_promo=price_promo*1.2;
        }
        else{
            price=price*1.12;
            price_promo=price_promo*1.12;
        }

        form.setFieldValue("price_visualised",price.toFixed(2));
        form.setFieldValue("price_promo_visualised",price_promo.toFixed(2));
    }

    const onPreview = async (file: UploadFile) => {
        let src = file.url as string;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handleChangeSelect = (value: string) => {
        console.log(`selected ${value}`);
        setPrice()
    };

    const [inputValue, setInputValue] = useState<number | null>(null);

    const handleInputChange = (value: number | null) => {
        // Check if the value is a number
        if (value !== null && !isNaN(value)) {
            // Check if the value is a float
            if (!Number.isInteger(value)) {
                // Convert the float to the nearest int
                const nearestInt = Math.round(value);
                setInputValue(nearestInt);
            } else {
                // If the value is already an integer, update the state with the integer value
                setInputValue(value);
            }
        } else {
            // Handle null or NaN cases
            setInputValue(null);
        }
    };
    const onFinish = (values: any) => {
        console.log("Product_recup -> ", values);
        // createProduct(values, fileList2, fileList);
        // window.location.href = "/dashboard";
    };

    const {Option} = Select;

    const options = [
        {label: 'Accastillage', value: 'Accastillage'},
        {label: 'Autres', value: 'Autres'},
        {label: 'Confort', value: 'Confort'},
        {label: 'Cordage', value: 'Cordage'},
        {label: 'Électricité', value: 'Électricité'},
        {label: 'Électronique', value: 'Électronique'},
        {label: 'Loisirs', value: 'Loisirs'},
        {label: 'Moteur', value: 'Moteur'},
        {label: 'Mouillage', value: 'Mouillage'},
        {label: 'Navigation', value: 'Navigation'},
        {label: 'Sécurité', value: 'Sécurité'},
        {label: 'Vêtements', value: 'Vêtements'}
    ];

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <ToastContainer/>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;
                }     
               
                @media (max-width: 1100px) {
                    #form_mobile_style {
                    margin-left:10% !important;
                }
                
               `}
            </style>
            <Form onFinish={onFinish} id={"form_mobile_style"}>
                    <div>
                        <h1 style={{fontSize: '24px'}}>Création de Produit</h1>
                    </div>
                    <div style={{marginTop: '20px', color: colorHeaderText}}>
                        <Row>
                            <Col style={{width: '30%'}}>
                                <h3 style={{marginBottom: '10px'}}>Image du produit mise en avant</h3>
                                <ImgCrop>
                                    <Upload
                                        name="image"
                                        action={`https://${process.env.REACT_APP_API_URL}` + "media/create"}
                                        listType="picture-card"
                                        fileList={fileList2}
                                        onChange={onChange2}
                                        headers={config}
                                        onPreview={onPreview}
                                        data={data}
                                        maxCount={1}
                                    >
                                        {imageUrl ?
                                            <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}
                                    </Upload>
                                </ImgCrop>
                                <Row style={{display: 'contents'}}>
                                    <h3 style={{marginBottom: '10px'}}>Galerie d'images (4 maximum)</h3>
                                    <Row>
                                        <div style={{display: 'flex'}}>
                                            <ImgCrop rotationSlider>
                                                <Upload
                                                    name="image"
                                                    action={`https://${process.env.REACT_APP_API_URL}` + "/media/create"}
                                                    listType="picture-card"
                                                    fileList={fileList}
                                                    onChange={onChange}
                                                    headers={config}
                                                    onPreview={onPreview}
                                                    data={data}
                                                >
                                                    {fileList.length < 4 && '+ Upload'}
                                                </Upload>
                                            </ImgCrop>
                                        </div>
                                    </Row>
                                </Row>
                            </Col>

                            <Col style={{width: '70%'}}>
                                <Row style={{marginBottom: '15px'}}>
                                    <Col flex={1} style={{marginRight: '15px'}}>
                                        <h3 style={{marginBottom: '10px'}}>Nom du produit</h3>
                                        <Form.Item name="product_name"
                                                   rules={[{required: true, message: 'Le nom du produit est requis.'}]}>
                                            <Input placeholder="Nom du produit"/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <h3 style={{marginBottom: '10px'}}>Description</h3>
                                        <Form.Item name="description">
                                            <TextArea rows={2}/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: '15px'}}>
                                    <Col flex={1} style={{marginRight: '15px'}}>
                                        <h3 style={{marginBottom: '10px'}}>SKU Produit</h3>
                                        <Form.Item name="product_sku">
                                            <Input placeholder="SKU Produit"/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        <h3 style={{marginBottom: '10px'}}>SKU Vendeur</h3>
                                        <Form.Item name="vendor_sku">
                                            <Input placeholder="SKU Vendeur"/>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                            <h3 style={{marginBottom: '10px'}}>Prix de cession (HT)</h3>
                                            <Form.Item name="price">
                                                <InputNumber placeholder="Prix" step={0.1} min={0} id="price_id"
                                                             style={{display: 'flex', width: '100%'}} onChange={setPrice}/>
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <h3 style={{marginBottom: '10px'}}>Prix de cession (HT) en promotion</h3>
                                            <Form.Item name="price_promo">
                                                <InputNumber placeholder="Prix en promotion" step={0.1} min={0}
                                                             id="price_promo_id"
                                                             style={{display: 'flex', width: '100%'}} onChange={setPrice}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                            <h3 style={{marginBottom: '10px'}}>Prix mis en vente (HT)</h3>
                                            <Form.Item name="price_visualised">
                                                <InputNumber placeholder="Prix" step={0.1} min={0}
                                                             style={{display: 'flex', width: '100%'}} disabled={true}/>
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            <h3 style={{marginBottom: '10px'}}>Prix en promotion mis en vente (HT)</h3>
                                            <Form.Item name="price_promo_visualised">
                                                <InputNumber placeholder="Prix en promotion" step={0.1} min={0}
                                                             style={{display: 'flex', width: '100%'}} disabled={true}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>Quantité</h3>
                                <Form.Item name="quantity">
                                    <InputNumber placeholder="Quantité" min={0} step={1} style={{width: '100%'}}
                                                 value={inputValue} onChange={handleInputChange}/>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>État</h3>
                                <Row style={{marginBottom: '20px'}}>
                                    <Form.Item name="state">
                                        <Select
                                            defaultValue="Sélectionner un état de la pièce"
                                            onChange={handleChangeSelect}
                                            style={{width: '100%'}}
                                            options={[
                                                {value: 'neuf', label: 'Neuf'},
                                                {value: 'très bon', label: 'Très bon'},
                                                {value: 'bon', label: 'Bon'},
                                                {value: 'défaut apparent', label: 'Défaut apparent'},
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col flex={1}>
                                <h3 style={{marginBottom: '10px'}}>Description de l'état de la pièce</h3>
                                <Form.Item name="state_description">
                                    <TextArea rows={2}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '10px'}}>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>Catégorie</h3>
                                <Form.Item name="product_categories">
                                    <Select
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        placeholder="Select options"
                                        optionLabelProp="label"
                                    >
                                        {options.map((option) => (
                                            <Option key={option.value} value={option.value} label={option.label}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>Marque</h3>
                                <Form.Item name="marque">
                                    <Input placeholder="Marque"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '15px'}}>
                            <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                <h3 style={{marginBottom: '10px'}}>Dimensions</h3>
                                <Form.Item name="depth">
                                    <InputNumber placeholder="Longueur (en centimètres)" step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                <Form.Item name="width">
                                    <InputNumber placeholder="Largeur (en centimètres)" step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                <Form.Item name="height">
                                    <InputNumber placeholder="Hauteur (en centimètres)" step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>Poids</h3>
                                <Form.Item name="weight">
                                    <InputNumber placeholder="Poids (en grammes)" step={0.01} min={0}
                                                 style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>Conditionnement</h3>
                                <Row style={{marginBottom: '20px'}}>
                                    <Form.Item name="conditioningType">
                                        <Select
                                            defaultValue="Sélectionner un type de conditionnement"
                                            onChange={handleChangeSelect}
                                            style={{width: '100%'}}
                                            options={[
                                                {value: 'centimètre', label: 'En centimètre'},
                                                {value: 'mètre', label: 'En mètre'},
                                                {value: 'Lot', label: 'Lot'},
                                                {value: 'Unité', label: 'Unité'},
                                                {value: 'millimètre', label: 'En millimètre'},
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col flex={1}>
                                <h3 style={{marginBottom: '10px'}}>Valeur du conditionnement</h3>
                                <Form.Item name="conditioningValue">
                                    <Input placeholder="Valeur du conditionnement" min={0} step={1}
                                           style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '10px'}}>
                            <Col flex={1}>
                                <h3 style={{marginBottom: '10px'}}>Description additionnelle</h3>
                                <Form.Item name="additional_description">
                                    <TextArea rows={2}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{justifyContent: 'end', marginBottom: '10px'}}>
                            <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                    htmlType="submit">Enregistrer</Button>
                        </Row>
                    </div>
                </Form>
        </div>
    );
}
