import React, {useEffect, useMemo, useState} from 'react';
import {UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Image, Input, InputNumber, Row, Select, Upload,Switch} from 'antd';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import TextArea from "antd/es/input/TextArea";
import {getAllCommission, getAllTVA, getProductBySlug, modifyProduct, ProductInterface} from "../../Products/Products";
import {Gallery, getMediaInfos, Medias} from "../../Media";
import {useTranslation} from "react-i18next";
import {getUserById_Test_R} from "../../User";
import {toast, ToastContainer} from "react-toastify";

type RequiredMark = boolean;


let USER_ROLE = "vendor_professional";

const user_id = localStorage.getItem('user_id');
if (user_id) {
    let user = await getUserById_Test_R(parseFloat(user_id))
    if (user) {
        for (const role of user.roles) {
            if (role.label === "vendor_private") {
                console.log("dashboard particulier")
                USER_ROLE = role.label
            }
        }
    }
}

let AllCommission = await getAllCommission();
let AllTVA = await getAllTVA();

interface props {
    slug: string | null
}


export default function ModifyProduct(props: props) {
    const {t} = useTranslation();
    const slug = props.slug
    const colorHeaderText = "#09154C"
    const colorProductBtn = "#7ED957"

    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [fileList2, setFileList2] = useState<UploadFile[]>([]);

    const handleChangeSelect = (value: string) => {
        console.log(`selected ${value}`);
        // setPrice();
    };
    const [unit_weight, setUnit_weight] = useState<string>('kg');
    const [commissionValue, setCommissionValue] = useState<number>(1.15)
    const [Text_commission_included, setText_commission_included] = useState<string>('*Avec notre commission de 15% incluse');
    const [Text_tva_apply, setText_tva_apply] = useState<string>('Appliquer TVA 20% (Ce produit est neuf ou d\'occasion)');
    const [inputValue, setInputValue] = useState<number | null>(null);

    // TVA amount auto
    let tva_amount_slug_to_take = 'France'; // à changer à fonction du pays et de la localisation du vendeur
    let tva_amount_taken = 20; // par défault si trouve pas allTVA
    if (AllTVA) {
        for (const TvA of AllTVA) {
            if( TvA.slug === tva_amount_slug_to_take){
                tva_amount_taken = TvA.amount
            }
        }
    }

    const handleInputChange = (value: number | null) => {
        // Check if the value is a number
        if (value !== null && !isNaN(value)) {
            // Check if the value is a float
            if (!Number.isInteger(value)) {
                // Convert the float to the nearest int
                const nearestInt = Math.round(value);
                setInputValue(nearestInt);
            } else {
                // If the value is already an integer, update the state with the integer value
                setInputValue(value);
            }
        } else {
            // Handle null or NaN cases
            setInputValue(null);
        }
    };

    const [form] = Form.useForm();

    function generateRandomNumber(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [TVAstate, setTVAState] = useState(false);

    const handleSwitchChange = (checked : boolean) => {
        console.log(`TVA ${checked}`);
        setTVAState(checked);
        setPrice(checked);

    };

    const setPrice = (tva : boolean) => {
        let price = form.getFieldValue("price");
        let price_promo = form.getFieldValue("price_promo");
        let diff = 0;
        console.log("TVA dans setPrice ",tva)
        console.log("TVA amount taken -> ",tva_amount_taken,"%");
        if (tva) {
            console.log("produit neuf")
            if (price_promo) {
                if(price_promo>price){
                    console.log("erreur prix promo > prix de base")
                    console.log(form.getFieldInstance("price_promo"))
                    // setDisplayErrorPromo(true)
                    // price_promo_error_rules.push({
                    //     type: 'number',
                    //     required: true,
                    //     message: 'Le prix promo ne peut être supérieur au prix de base',
                    // });
                    diff=price_promo-price
                    price_promo=price_promo-diff
                    price_promo-=0.01
                    form.setFieldValue("price_promo", price_promo.toFixed(2));
                }
                price_promo = price_promo * commissionValue;
                price_promo = price_promo * ( 1 + (tva_amount_taken/100));
                form.setFieldValue("price_promo_visualised", price_promo.toFixed(2));
            }

            // Commission auto
            let commission_name_to_take = 'base';
            if (user_id === '1209'){
                commission_name_to_take = 'fairbanks'
            }
            if (user_id === '2432'){
                commission_name_to_take = 'vdm'
            }
            let commission_taken =  (commissionValue - 1) * 100; // par défault si trouve pas allCommission, on utlise la commission du produit avant
            console.log("commission taken old -> ",commission_taken,"%");
            if (AllCommission) {
                for (const commission of AllCommission) {
                    if( commission.name === commission_name_to_take){
                        commission_taken = commission.value
                    }
                }
                price = price * ( 1 + (commission_taken/100));
                price = price * ( 1 + (tva_amount_taken/100));
                setText_commission_included('*Avec notre commission de '+commission_taken+'% incluse')
            }
            else {
                price = price * commissionValue;
                price = price * (1 + (tva_amount_taken / 100));
                setText_commission_included('*Avec notre commission de ' + commission_taken + '% incluse')
            }
            console.log("commission taken new -> ",commission_taken,"%");
        } else {
            console.log("produit occasion")
            if (price_promo) {
                if (price_promo>price) {
                    console.log("erreur prix promo > prix de base")
                    diff=price_promo-price
                    price_promo=price_promo-diff
                    price_promo-=0.01
                    form.setFieldValue("price_promo", price_promo.toFixed(2));
                }
                price_promo = price_promo * 1.18;
                form.setFieldValue("price_promo_visualised", price_promo.toFixed(2));
            }
            price = price * 1.18;
            setText_commission_included('*Avec notre commission de 15% incluse');
        }
        form.setFieldValue("price_visualised", price.toFixed(2));

    };



    const onFinish = (values: any) => {

        if(unit_weight==='kg'){ // si kg remettre en g
            values.weight=values.weight*1000
        }

        if(values.state){ // neuf
            values.state='Neuf' // 1
        }
        else{ // occasion
            values.state='Très bon'  // 2
        }
        console.log('Success:', values);
        if (product.product_name !== form.getFieldValue("product_name")) {
            console.log("name of product changed : ", form.getFieldValue("product_name"))
            const minRange = 0;
            const maxRange = 10000000;
            const randomValue: number = generateRandomNumber(minRange, maxRange);
            let slug = form.getFieldValue("product_name").replaceAll(" ", "-")
            slug = slug + "-" + randomValue;
            form.setFieldsValue({slug: slug})
            console.log("slug of product changed : ", slug)
        } else {
            console.log("slug not changed : ", product.slug)
            form.setFieldsValue({slug: product.slug})
        }
        if(selectedImage!== null){
            modifyProduct(values, selectedImage)
        }
        else {
            alert("Veuillez sélectionner au moins une image")
        }

    };

    const {Option} = Select;


    const options = useMemo(() => [
        {label: 'Accastillage', value: 'accastillage'},
        {label: t('Accessoires'), value: 'accessoires'},
        {label: 'Autres', value: 'autres'},
        {label: 'Confort', value: 'confort'},
        {label: 'Cordage', value: 'cordage'},
        {label: 'Électricité', value: 'electricite'},
        {label: 'Électronique', value: 'electronique'},
        {label: 'Loisirs', value: 'loisirs'},
        {label: 'Moteur', value: 'moteur'},
        {label: 'Mouillage', value: 'mouillage'},
        {label: 'Navigation', value: 'navigation'},
        {label: 'Sécurité', value: 'securite'},
        {label: 'Vêtements', value: 'vetements'},
    ], []);

    const [HiddenTVABouton, setHiddenTVABouton] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        if (USER_ROLE === 'vendor_private') {
            // selectOptions.shift();
            setHiddenTVABouton(true)
        }

        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [product, setProduct] = useState<ProductInterface>({
        quantity: 0,
        description: "",
        dimensions: "",
        price: 0,
        product_name: "",
        weight: 0,
        id: 0,
        slug: "",
        price_promo: 0,
        product_sku: "",
        vendor_sku: "",
        creation_date: 0,
        product_image: 0,
        tva: false,
        marque: "",
        gallery: [],
        product_categories: [{
            "id": 0,
            "category_name": '',
            "category_slug": '',
            "products": []
        }],
        product_keywords: "",
        vendor_id: 0,
        has_tva:true,
        backorder: false,
        commission: {
            name:"",
            value:15,
            id: 1
        },
        no_stock : false

    });
    const [dataFetched, setDataFetched] = useState(false);
    const [selectedImage, setSelectedImage] = useState<Medias[] | null>(null);
    // const [pictures, setPictures] = useState<Medias[]>([]);

    function filterIt(arr: any, searchKey: any) {
        return arr.filter(function (obj: any) {
            return Object.keys(obj).some(function (key) {
                return obj[key].includes(searchKey);
            })
        });
    }

    useEffect(() => {
        const fetchProductInfos = async () => {
            const productData = await getProductBySlug(slug as string);
            console.log(productData)
            const featuredImage = await getMediaInfos(productData.product_image);
            let galleryImages:Medias[] = []
            for (const image of productData.gallery) {
                galleryImages.push(await getMediaInfos(image))
            }
            let TVA= false;
            setSelectedImage( [featuredImage, ...galleryImages]);
            setCommissionValue(1 + (productData.commission.value / 100))
            if (productData.state?.toString() === '1') {
                productData.state = "neuf"
                TVA=true
            }
            if (productData.state?.toString() === '2') {
                productData.state = "très bon"
                TVA= false
            }
            if (productData.state?.toString() === '3') {
                productData.state = "bon"
                TVA= false
            }
            if (productData.state?.toString() === '4') {
                productData.state = "défault apparent"
                TVA= false
            }
            if (productData.conditioningType?.toString() === '1') {
                productData.conditioningType = "En centimètre"
            }
            if (productData.conditioningType?.toString() === '2') {
                productData.conditioningType = "En mètre"
            }
            if (productData.conditioningType?.toString() === '3') {
                productData.conditioningType = "Lot"
            }
            if (productData.conditioningType?.toString() === '4') {
                productData.conditioningType = "Unité"
            }
            if (productData.conditioningType?.toString() === '5') {
                productData.conditioningType = "En millimètre"
            }
            let depth = parseInt(productData.dimensions.split("x")[0])
            let width = parseInt(productData.dimensions.split("x")[1])
            let height = parseInt(productData.dimensions.split("x")[2])
            let price_visualised = productData.price
            let price = price_visualised;
            let price_promo_visualised = productData.price_promo;
            let price_promo = price_promo_visualised;
            let state = productData.state;
            console.log(state)
            setText_tva_apply('Appliquer TVA '+tva_amount_taken+'% (Ce produit est neuf ou d\'occasion)')
            if (state === "neuf") {
                setTVAState(true)
                price = price / (1 + (productData.commission.value / 100));
                price_promo = price_promo / (1 + (productData.commission.value / 100));
                price = price / ( 1 + (tva_amount_taken/100));
                price_promo = price_promo / ( 1 + (tva_amount_taken/100));
                setText_commission_included('*Avec notre commission de '+productData.commission.value+'% incluse')
            } else {
                setTVAState(false)
                price = price / 1.18;
                price_promo = price_promo / 1.18;
                setText_commission_included('*Avec notre commission de 15% incluse')
            }

            let categories: { label: string, value: string }[];
            categories = []
            for (let category of productData.product_categories) {
                if (!options.some(option => option.value === category.category_slug)) {
                    categories.push(filterIt(options, category.category_name)[0]);
                    // console.log(productData.product_categories)
                }
            }
            console.log("poids de l'article ",productData.weight,"g")
            if(productData.weight < 1000 ){
                setUnit_weight('g')
                form.setFieldsValue({
                    unit_weight: 'g',
                });
                console.log("en g")
            }
            else{
                productData.weight = productData.weight / 1000
                setUnit_weight('kg')
                form.setFieldsValue({
                    unit_weight: 'kg',
                });
                console.log("en kg")

            }

            console.log("poids de l'article converti ",productData.weight,unit_weight)

            // console.log(categories);
            setProduct(productData);
            setDataFetched(true)
            form.setFieldsValue({
                ...productData,
                depth: depth,
                width: width,
                height: height,
                product_categories: categories,
                price_visualised: price_visualised.toFixed(2),
                price_promo_visualised: price_promo_visualised.toFixed(2),
                price: Number(price.toFixed(2)),
                price_promo: price_promo.toFixed(2),
                product_image: productData.product_image,
                gallery: productData.gallery,
                state : TVA
            });



        };

        setDataFetched(true)
        if (!dataFetched) {
            fetchProductInfos()
        }
    }, [dataFetched, slug, form, options, unit_weight])


    const handleSelectImage = (image: Medias[]) => {
        setSelectedImage(image);
        console.log(image)
    };


    const config_name = {
        rules: [{type: 'string' as const, required: true, message: t('Veuillez entrer le nom de votre produit')}],
    }
    const config_desc = {
        rules: [{
            type: 'string' as const,
            required: true,
            message: t('Veuillez entrer la description de votre produit')
        }],
    }
    // const config_price = {
    //     rules: [{type: 'string' as const, required: true, message: t('Veuillez entrer le prix de votre produit')}],
    // }

    const config_price = {
        rules: [
            {
                type: 'number' as const,
                required: true,
                message: t('Veuillez entrer le prix de votre produit'),
            }
        ],
    }

    const config_stock = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrer la quantité de votre produit')}],
    }

    const config_state = {
        rules: [{type: 'string' as const, required: true, message: t('Veuillez entrer l\'état de votre produit')}],
    }
    const config_desc_state = {
        rules: [{
            type: 'string' as const,
            required: true,
            message: t('Veuillez entrer la description de l\'état de votre produit')
        }],
    }
    const config_category = {
        rules: [{
            type: 'array' as const,
            required: true,
            message: t('Veuillez choisir une catégorie pour votre produit')
        }],
    }
    const config_brand = {
        rules: [{type: 'string' as const, required: false, message: t('Veuillez entrez la marque de votre produit')}],
    }

    const config_longueur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la longueur de votre pièce')}],
    }

    const config_largeur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la largeur de votre pièce')}],
    }

    const config_hauteur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la hauteur de votre pièce')}],
    }

    const config_poids = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez le poids de votre pièce')}],
    }
    const config_conditionning_type = {
        rules: [{
            type: 'string' as const,
            required: false,
            message: t('Veuillez choisir le type de conditionnement de votre produit'),
        }],
    }

    const config_conditionning_value = {
        rules: [{
            type: 'string' as const,
            required: false,
            message: t('Veuillez entrez la valeur du conditionnement de votre produit')
        }],
    }

    return (
        <div>
            <ToastContainer/>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;     
                }
               `}
            </style>
            <div>
                <Gallery onSelect={handleSelectImage} preSelectedImages={selectedImage}/>
                {selectedImage ?
                    <Image.PreviewGroup
                        preview={{
                            onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                        }}
                    >
                        {selectedImage.map((image, index) => (
                            <Image
                                key={image.id}
                                src={`https://${process.env.REACT_APP_API_URL}`+ `/media/public/products/${image.url}`}
                                alt={image.name}
                                width={200}
                            />
                        ))}

                    </Image.PreviewGroup>
                    : <></>}
            </div>
                <div style={{margin: '10px'}}>
                    <Form onFinish={onFinish}
                          initialValues={{
                              remember: true,
                          }}
                          form={form}>

                        <div>
                            <h1 style={{fontSize: '24px'}}>Modification du Produit : {product.product_name}</h1>
                            <Form.Item name="id"
                                       rules={[{required: false, message: ''}]}
                                       style={{marginBottom: '10px', width: '95%', display: 'none'}}>
                                <Input value={product.id}/>
                            </Form.Item>
                            <Form.Item name="slug"
                                       rules={[{required: false, message: ''}]}
                                       style={{marginBottom: '10px', width: '95%', display: 'none'}}>
                                <Input/>
                            </Form.Item>
                        </div>
                        <div style={{marginTop: '20px', color: colorHeaderText}}>
                            <Row>
                                <Col style={{width: '70%'}}>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col flex={1} style={{marginRight: '15px'}}>
                                            {/*<h3 style={{marginBottom: '10px'}}>Nom du produit</h3>*/}
                                            <Form.Item name="product_name" label={t('Nom du produit')} tooltip={t('Ce champ est requis')} {...config_name}>
                                                <Input placeholder={t('Donnez un nom à votre annonce')} showCount maxLength={256} allowClear={true}/>
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            {/*<h3 style={{marginBottom: '10px'}}>Description</h3>*/}
                                            <Form.Item name="description" label={t('Description')}
                                                       tooltip={t('Ce champ est requis')} {...config_desc}>
                                                <TextArea placeholder={t('Ecrivez une description pour donner plus d\'informations sur votre produit...')} rows={2} showCount maxLength={1024} allowClear={true}/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col flex={1} style={{marginRight: '15px' , display : HiddenTVABouton ? 'none' : ''}}>
                                            {/*<h3 style={{marginBottom: '10px'}}>État</h3>*/}
                                            <Form.Item name="state" label={Text_tva_apply} tooltip={t('Ce champ est requis')} >
                                                {/*<Select*/}
                                                {/*    defaultValue={t('Sélectionner un état de la pièce')}*/}
                                                {/*    onChange={handleChangeSelect}*/}
                                                {/*    style={{width: '100%'}}*/}
                                                {/*    options={selectOptions}*/}
                                                {/*    id={"state"}*/}
                                                {/*/>*/}
                                                <Switch checked={TVAstate} onChange={handleSwitchChange} checkedChildren={<span>Oui</span>} unCheckedChildren={<span>Non</span>} />
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            {/*<h3 style={{marginBottom: '10px'}}>Description de l'état de la pièce</h3>*/}
                                            <Form.Item name="state_description" label={t('Description de l\'état de la pièce')} tooltip={t('Ce champ est requis')} {...config_desc_state}>
                                                <TextArea placeholder={t('ex : pièce neuve sans rayures...')} rows={2} showCount maxLength={256} allowClear={true} />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                    <div>
                                        <Row style={{marginBottom: '15px'}}>
                                            <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                                {/*<h3 style={{marginBottom: '10px'}}>Prix de cession (HT)</h3>*/}
                                                <Form.Item name="price" label={t('Prix de vente (HT)')} tooltip={t('Ce champ est requis')} {...config_price}>
                                                    <InputNumber placeholder={t('Indiquez le prix souhaité pour la vente de ce produit')} step={0.1} min={0} id="price_id"
                                                                 style={{display: 'flex', width: '100%'}}
                                                                 onChange={(value) => setPrice(TVAstate)} addonAfter="€" />
                                                </Form.Item>
                                            </Col>
                                            <Col flex={1}>
                                            {/*    <h3 style={{marginBottom: '10px'}}>{t('Prix de cession (HT) en promotion')}</h3>*/}
                                            {/*    <Form.Item name="price_promo">*/}
                                            {/*        <InputNumber placeholder={t('Indiquez le prix promo souhaité pour la vente de ce produit')} step={0.1} min={0}*/}
                                            {/*                     id="price_promo_id"*/}
                                            {/*                     style={{display: 'flex', width: '100%'}}*/}
                                            {/*                     onChange={(value) => setPrice(TVAstate)} addonAfter="€"/>*/}
                                            {/*    </Form.Item>*/}
                                                <h3 style={{marginBottom: '10px'}}>{t('Prix de vente (TTC) ')} <span style={{fontSize: 'smaller',fontWeight: 'lighter'}}>{Text_commission_included}</span></h3>
                                                <Form.Item name="price_visualised">
                                                    <InputNumber placeholder={t('Prix TTC affiché en ligne sur la marketplace')} step={0.1} min={0}
                                                                 style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row style={{marginBottom: '15px'}}>
                                            {/*<Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>*/}
                                            {/*    <h3 style={{marginBottom: '10px'}}>{t('Prix mis en vente (TTC)')}</h3>*/}
                                            {/*    <Form.Item name="price_visualised">*/}
                                            {/*        <InputNumber placeholder={t('Prix TTC affiché en ligne sur le site')} step={0.1} min={0}*/}
                                            {/*                     style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>*/}
                                            {/*    </Form.Item>*/}
                                            {/*</Col>*/}
                                            {/*<Col flex={1}>*/}
                                            {/*    <h3 style={{marginBottom: '10px'}}>{t('Prix en promotion mis en vente (TTC)')}</h3>*/}
                                            {/*    <Form.Item name="price_promo_visualised">*/}
                                            {/*        <InputNumber placeholder={t('Prix en promo TTC affiché en ligne sur le site')} step={0.1} min={0}*/}
                                            {/*                     style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>*/}
                                            {/*    </Form.Item>*/}
                                            {/*</Col>*/}
                                        </Row>

                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    {/*<h3 style={{marginBottom: '10px'}}>Quantité</h3>*/}
                                    <Form.Item name="quantity" label={t('Quantité')}
                                               tooltip={t('Ce champ est requis')} {...config_stock}>
                                        <InputNumber placeholder={'Quantité du produit en stock'} min={0} step={1} style={{width: '100%'}}
                                                     value={inputValue} onChange={handleInputChange}/>
                                    </Form.Item>
                                </Col>
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    {/*<h3 style={{marginBottom: '10px'}}>Catégorie</h3>*/}
                                    <Form.Item name="product_categories" label={t('Catégorie')}
                                               tooltip={t('Ce champ est requis')} {...config_category}>
                                        <Select
                                            mode="multiple"
                                            style={{width: '100%'}}
                                            placeholder={t('Sélectionner des catégories pour votre produit')}
                                            optionLabelProp="label">
                                            {options.map((option) => (
                                                <Option key={option.value} value={option.value} label={option.label}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>

                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    <h3 style={{marginBottom: '10px'}}>{t('Référence Produit')}</h3>
                                    <Form.Item name="product_sku">
                                        <Input placeholder={t('Référence du Produit')} showCount maxLength={64} allowClear={true}/>
                                    </Form.Item>
                                </Col>
                                {/*<Col flex={1}>*/}
                                {/*    <h3 style={{marginBottom: '10px'}}>{t('Référence Vendeur')}</h3>*/}
                                {/*    <Form.Item name="vendor_sku">*/}
                                {/*        <Input placeholder={t('Référence Vendeur')}/>*/}
                                {/*    </Form.Item>*/}
                                {/*</Col>*/}
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    {/*<h3 style={{marginBottom: '10px'}}>Marque</h3>*/}

                                    <Form.Item name="marque" label={t('Marque')}>
                                        <Input placeholder={t('Marque de la pièce')} showCount maxLength={256} allowClear={true}/>

                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{marginBottom: '15px'}}>
                                <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                    <h3 style={{marginBottom: '10px'}}>{t('Dimensions du colis')}</h3>
                                    <Form.Item name="depth" label={t('Longueur  (en centimètres)')}
                                               tooltip={t('Ce champ est requis')} {...config_longueur}>
                                        <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                     style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                    </Form.Item>
                                </Col>
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                    <Form.Item name="width" label={t('Largeur (en centimètres)')}
                                               tooltip={t('Ce champ est requis')} {...config_largeur}>
                                        <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                     style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                    </Form.Item>
                                </Col>
                                <Col flex={1}>
                                    <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                    <Form.Item name="height" label={t('Hauteur (en centimètres)')}
                                               tooltip={t('Ce champ est requis')} {...config_hauteur}>
                                        <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                     style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col flex={1} style={{ marginRight: '15px' }}>
                                    <Row gutter={8} align="middle">
                                        <Col style={{marginTop: "-1%"}}>
                                            <Form.Item name="weight" label={unit_weight === 'kg' ? t('Poids du colis (en kg)') : t('Poids du colis (en grammes)')} tooltip={t('Ce champ est requis')}{...config_poids}>
                                                <InputNumber placeholder={unit_weight === 'kg' ? t('ex : 1') : t('ex : 1000')} step={0.01} min={0} />
                                                {/*<InputNumber placeholder={t('ex : 1000')} step={0.01} min={0} style={{width: '100%'}} addonAfter="g"/>*/}
                                                {/*<InputNumber placeholder={t('ex : 10')} step={0.01} min={0} style={{width: '100%'}} addonAfter="kg"/>*/}
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Select  value={unit_weight} defaultValue={unit_weight} onChange={(value) => {setUnit_weight(value);}} >
                                                <Option value="kg">kg</Option>
                                                <Option value="g">g</Option>
                                            </Select>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col flex={1} style={{marginRight: '15px'}}>
                                    {/*<h3 style={{marginBottom: '10px'}}>Conditionnement</h3>*/}
                                    <Row style={{marginBottom: '20px'}}>
                                        <Form.Item name="conditioningType" label={t('Conditionnement')} style={{display:"none"}}
                                                   tooltip={t('Ce champ est requis')} {...config_conditionning_type}>
                                            <Select
                                                defaultValue="unite"
                                                onChange={handleChangeSelect}
                                                style={{width: '100%'}}
                                                options={[
                                                    {value: 'unite', label: t('Unité')},
                                                    {value: 'millimetre', label: t('En millimètre')},
                                                    {value: 'centimetre', label: t('En centimètre')},
                                                    {value: 'metre', label: t('En mètre')},
                                                    {value: 'lot', label: t('Lot')}
                                                ]}
                                            />
                                        </Form.Item>
                                    </Row>
                                </Col>
                                <Col flex={1}>
                                    {/*<h3 style={{marginBottom: '10px'}}>Valeur du conditionnement</h3>*/}
                                    <Form.Item name="conditioningValue" label={t('Valeur du conditionnement')} style={{display:"none"}}
                                               tooltip={t('Ce champ est requis')} {...config_conditionning_value}>
                                        <Input placeholder={t('Valeur du conditionnement')} min={1} step={1} defaultValue="1"
                                               style={{width: '100%'}}/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            {/*<Row style={{marginBottom: '10px'}}>*/}
                            {/*    <Col flex={1}>*/}
                            {/*        <h3 style={{marginBottom: '10px'}}>Description additionnelle</h3>*/}
                            {/*        <Form.Item name="additional_description">*/}
                            {/*            <TextArea rows={2}/>*/}
                            {/*        </Form.Item>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <Row style={{marginBottom: '150px'}}>
                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                        htmlType="submit">Enregistrer</Button>
                            </Row>
                        </div>
                    </Form>
                </div>

        </div>
    )
        ;

}
;
