import React, {ReactNode, useEffect, useState} from 'react';
import {LoadingOutlined, PlusOutlined, UploadOutlined} from '@ant-design/icons';
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Upload, Image,Switch } from 'antd';
import type {UploadChangeParam} from 'antd/es/upload';
import type {RcFile, UploadFile, UploadProps} from 'antd/es/upload/interface';
import ImgCrop from 'antd-img-crop';
import TextArea from "antd/es/input/TextArea";
import {createProduct, getAllCommission, getAllTVA} from "../../Products/Products";
import {getUserById_Test_R} from "../../User";
import {useTranslation} from "react-i18next";
import {Gallery, Medias} from '../../Media';
import {ToastContainer} from "react-toastify";
import { Rule } from 'antd/lib/form';

type RequiredMark = boolean;


let USER_ROLE = "vendor_professional";

const user_id = localStorage.getItem('user_id');
if (user_id) {
    let user = await getUserById_Test_R(parseFloat(user_id))
    if (user) {
        for (const role of user.roles) {
            if (role.label === "vendor_private") {
                console.log("dashboard particulier")
                USER_ROLE = role.label
            }
        }
    }
}

let AllCommission = await getAllCommission();
let AllTVA = await getAllTVA();

export default function CreateProduct() {
    localStorage.removeItem('createProduct'); //  pour enlever dans le cache après redirection sur mobile depuis "publier"

    const {t} = useTranslation();
    const [form] = Form.useForm();
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [unit_weight, setUnit_weight] = useState<string>('kg');
    const [Text_commission_included, setText_commission_included] = useState<string>('*Avec notre commission de 25% incluse');
    const [Text_tva_apply, setText_tva_apply] = useState<string>('Appliquer TVA 20% (Ce produit est neuf ou d\'occasion)');

    // Commission auto
    let commission_name_to_take = 'base';
    if (user_id === '1209'){
        commission_name_to_take = 'fairbanks'
    }
    if (user_id === '2432'){
        commission_name_to_take = 'vdm'
    }
    let commission_taken = 15; // par défault si trouve pas allCommission
    if (AllCommission) {
        for (const commission of AllCommission) {
            if( commission.name === commission_name_to_take){
                commission_taken = commission.value
            }
        }
    }

    // TVA amount auto
    let tva_amount_slug_to_take = 'France'; // à changer à fonction du pays et de la localisation du vendeur
    let tva_amount_taken = 20; // par défault si trouve pas allTVA
    if (AllTVA) {
        for (const TvA of AllTVA) {
            if( TvA.slug === tva_amount_slug_to_take){
                tva_amount_taken = TvA.amount
            }
        }
    }

    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();
    const config = {
        // ContentType: 'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: `Bearer ${localStorage.getItem('token')}`
    }

    const data = {
        name: "",
        description: ""
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/> : <PlusOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
            <div style={{marginTop: 8}}>{t('Ajouter une image')}</div>
        </div>
    );

    const getBase64 = (img: RcFile, callback: (url: string) => void) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result as string));
        reader.readAsDataURL(img);
    };

    const beforeUpload = (file: RcFile) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    };


    const handleChangeSelect = (value: string) => {
        console.log(`selected ${value}`);
        // setPrice()
    };


    const [inputValue, setInputValue] = useState<number | null>(null);

    const handleInputChange = (value: number | null) => {
        // Check if the value is a number
        if (value !== null && !isNaN(value)) {
            // Check if the value is a float
            if (!Number.isInteger(value)) {
                // Convert the float to the nearest int
                const nearestInt = Math.round(value);
                setInputValue(nearestInt);
            } else {
                // If the value is already an integer, update the state with the integer value
                setInputValue(value);
            }
        } else {
            // Handle null or NaN cases
            setInputValue(null);
        }
    };

    const [TVAstate, setTVAState] = useState(true);

    const handleSwitchChange = (checked : boolean) => {
        console.log(`TVA ${checked}`);
        setTVAState(checked);
        setPrice(checked);
    };

    const [DisplayErrorPromo, setDisplayErrorPromo] = useState(false);
    const setPrice = (tva : boolean) => {
        let price = form.getFieldValue("price");
        let price_promo = form.getFieldValue("price_promo");
        // let price_promo_error_rules = form.getFieldInstance("price_promo").rules
        let diff = 0;
        setDisplayErrorPromo(false)
        console.log("TVA dans setPrice ",tva)
        console.log("TVA amount taken -> ",tva_amount_taken,"%");
        console.log("commission taken -> ",commission_taken,"%");
        if (tva) {
            console.log("produit neuf")
            if (price_promo) {
                if(price_promo>price){
                    console.log("erreur prix promo > prix de base")
                    console.log(form.getFieldInstance("price_promo"))
                    // setDisplayErrorPromo(true)
                    // price_promo_error_rules.push({
                    //     type: 'number',
                    //     required: true,
                    //     message: 'Le prix promo ne peut être supérieur au prix de base',
                    // });
                    diff=price_promo-price
                    price_promo=price_promo-diff
                    price_promo-=0.01
                    form.setFieldValue("price_promo", price_promo.toFixed(2));
                }
                price_promo = price_promo * ( 1 + (commission_taken/100));
                price_promo = price_promo * ( 1 + (tva_amount_taken/100));
                form.setFieldValue("price_promo_visualised", price_promo.toFixed(2));
            }
            price = price * ( 1 + (commission_taken/100));
            price = price * ( 1 + (tva_amount_taken/100));
            setText_commission_included('*Avec notre commission de 25% incluse')
        } else {
            console.log("produit occasion")
            if (price_promo) {
                if (price_promo>price) {
                    console.log("erreur prix promo > prix de base")
                    setDisplayErrorPromo(true)
                    diff=price_promo-price
                    price_promo=price_promo-diff
                    price_promo-=0.01
                    form.setFieldValue("price_promo", price_promo.toFixed(2));
                }
                price_promo = price_promo * 1.18;
                form.setFieldValue("price_promo_visualised", price_promo.toFixed(2));
            }
            price = price * 1.18;
            setText_commission_included('*Avec notre commission de 25% incluse')
        }
        if(price) {form.setFieldValue("price_visualised", price.toFixed(2));}

    };


    const [selectedImage, setSelectedImage] = useState<Medias[] | null>(null);

    const handleSelectImage = (image: Medias[]) => {
        setSelectedImage(image);
        console.log(image)
    };





    const onFinish = (values: any) => {
        if(unit_weight==='kg'){ // si kg remettre en g
            values.weight=values.weight*1000
        }

        if(values.state){ // neuf
            values.state='Neuf' // 1
        }
        else{ // occasion
            values.state='Très bon'  // 2
        }
        console.log("Product_recup -> ", values.marque);
        if(selectedImage){
            createProduct(values, selectedImage);
        } else {
            alert("Veuillez sélectionner au moins une image")
        }
        // window.location.href = "/dashboard";
    };

    const {Option} = Select;

    const options = [
        {label: t('Accastillage'), value: 'Accastillage'},
        {label: t('Accessoires'), value: 'Accessoires'},
        {label: t('Autres'), value: 'Autres'},
        {label: t('Confort'), value: 'Confort'},
        {label: t('Cordage'), value: 'Cordage'},
        {label: t('Électricité'), value: 'Électricité'},
        {label: t('Électronique'), value: 'Électronique'},
        {label: t('Loisirs'), value: 'Loisirs'},
        {label: t('Moteur'), value: 'Moteur'},
        {label: t('Mouillage'), value: 'Mouillage'},
        {label: t('Navigation'), value: 'Navigation'},
        {label: t('Sécurité'), value: 'Sécurité'},
        {label: t('Vêtements'), value: 'Vetements'}
    ];

    const selectOptions = [
        {label: t('Neuf'), value: 'Neuf'},
        {label: t('Très bon'), value: 'Très bon'},
        {label: t('Bon'), value: 'Bon'},
        {label: t('Défaut apparent'), value: 'Défaut apparent'},
    ];

    const [HiddenTVABouton, setHiddenTVABouton] = useState<boolean>(false);

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {

        if (USER_ROLE === 'vendor_private') {
            // selectOptions.shift();
            setHiddenTVABouton(true)
        }

        setText_commission_included('*Avec notre commission de 25% incluse')
        setText_tva_apply('Appliquer TVA '+tva_amount_taken+'% (Ce produit est neuf ou d\'occasion)')

        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    const [requiredMark, setRequiredMarkType] = useState<RequiredMark>(true);

    const onRequiredTypeChange = ({requiredMarkValue}: { requiredMarkValue: RequiredMark }) => {
        setRequiredMarkType(requiredMarkValue);
    }


    const config_name = {
        rules: [
            {
                type: 'string' as const,
                required: true,
                message: t('Veuillez entrer le nom de votre produit'),
            }
            ],
    }

    const config_desc = {
        rules: [
            {
                type: 'string' as const,
                required: true,
                message: t('Veuillez entrer la description de votre produit'),
                // validator: (_ : any , value : any) => {
                //     if (value.length > 1024) {
                //         return Promise.reject('La description ne doit pas dépasser 1024 caractères');
                //     }
                //     return Promise.resolve();
                // },
            },
        ],
    };


    const config_price = {
        rules: [
            {
                type: 'number' as const,
                required: true,
                message: t('Veuillez entrer le prix de votre produit'),
            }
            ],
    }


    const config_stock = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrer la quantité de votre produit')}],
    }

    const config_state = {
        rules: [{type: 'string' as const, required: true, message: t('Veuillez entrer l\'état de votre produit')}],
    }

    const config_desc_state = {
        rules: [
            {
                type: 'string' as const,
                required: true,
                message: t('Veuillez entrer la description de l\'état de votre produit'),
                validator: (_ : any , value : any) => {
                    if (value.length > 256) {
                        return Promise.reject('La description de l\'état de votre produit ne doit pas dépasser 256 caractères');
                    }
                    return Promise.resolve();
                },
            }
        ],
    }
    const config_category = {
        rules: [{
            type: 'array' as const,
            required: true,
            message: t('Veuillez choisir une catégorie pour votre produit')
        }],
    }

    const config_brand = {
        rules: [
            {
                type: 'string' as const,
                required: true,
                message: t('Veuillez entrez la marque de votre produit'),
                validator: (_ : any , value : any) => {
                    if (value.length > 256) {
                        return Promise.reject('La marque de votre produit ne doit pas dépasser 256 caractères');
                    }
                    return Promise.resolve();
                },
            }
        ],
    }

    const config_longueur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la longueur de votre pièce')}],
    }

    const config_largeur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la largeur de votre pièce')}],
    }

    const config_hauteur = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez la hauteur de votre pièce')}],
    }

    const config_poids = {
        rules: [{type: 'number' as const, required: true, message: t('Veuillez entrez le poids de votre pièce')}],
    }
    const config_conditionning_type = {
        rules: [{
            type: 'string' as const,
            required: false,
            message: t('Veuillez choisir le type de conditionnement de votre produit'),
        }],
    }

    const config_conditionning_value = {
        rules: [{
            type: 'string' as const,
            required: false,
            message: t('Veuillez entrez la valeur du conditionnement de votre produit')
        }],
    }




    return (
        <div>
            <ToastContainer/>
            <style>
                {`
                .ant-row.ant-form-item-row.css-dev-only-do-not-override-txh9fw{
                display : contents !important ;
                }      
                :where(.css-dev-only-do-not-override-txh9fw).ant-form-item .ant-form-item-label >label::after {
                content : "" !important ;
                }    
               
                @media (max-width: 1100px) {
                    #form_mobile_style {
                    margin-left:10% !important;
                }
      
               `}
            </style>
            <Form onFinish={onFinish} form={form} id={"form_mobile_style"}>
                    <div>
                        <h1 style={{fontSize: '24px'}}>{t('Création d\'un Produit')}</h1>
                    </div>
                    <div>
                        <Gallery onSelect={handleSelectImage} preSelectedImages={null}/>
                        {selectedImage ?
                            <Image.PreviewGroup
                                preview={{
                                    onChange: (current, prev) => console.log(`current index: ${current}, prev index: ${prev}`),
                                }}>
                                {selectedImage.map((image, index) => (
                                    <Image
                                        key={image.id}
                                        src={`https://${process.env.REACT_APP_API_URL}`+ `/media/public/products/${image.url}`}
                                        alt={image.name}
                                        width={200}
                                    />
                                ))}

                            </Image.PreviewGroup>
                            : <></>}
                    </div>
                    <div style={{marginTop: '20px', color: colorHeaderText}}>
                        <Row>
                            {/*<Col style={{width: '30%'}}>*/}
                            {/*    <h3 style={{marginBottom: '10px'}}>{t('Image du produit mise en avant')}</h3>*/}
                            {/*    <ImgCrop>*/}
                            {/*        <Upload*/}
                            {/*            name="image"*/}
                            {/*            action="https://beta.captainchercheur.com/media/create"*/}
                            {/*            listType="picture-card"*/}
                            {/*            fileList={fileList2}*/}
                            {/*            onChange={onChange2}*/}
                            {/*            headers={config}*/}
                            {/*            onPreview={onPreview}*/}
                            {/*            data={data}*/}
                            {/*            maxCount={1}*/}
                            {/*        >*/}
                            {/*            {imageUrl ?*/}
                            {/*                <img src={imageUrl} alt="avatar" style={{width: '100%'}}/> : uploadButton}*/}
                            {/*        </Upload>*/}
                            {/*    </ImgCrop>*/}
                            {/*    <Row style={{display: 'contents'}}>*/}
                            {/*        <h3 style={{marginBottom: '10px'}}>{t('Galerie d\'images')} (4 maximum)</h3>*/}
                            {/*        <Row>*/}
                            {/*            <div style={{display: 'flex'}}>*/}
                            {/*                <ImgCrop rotationSlider>*/}
                            {/*                    <Upload*/}
                            {/*                        name="image"*/}
                            {/*                        action="https://beta.captainchercheur.com/media/create"*/}
                            {/*                        listType="picture-card"*/}
                            {/*                        fileList={fileList}*/}
                            {/*                        onChange={onChange}*/}
                            {/*                        headers={config}*/}
                            {/*                        onPreview={onPreview}*/}
                            {/*                        data={data}*/}
                            {/*                    >*/}
                            {/*                        {fileList.length < 4 && '+ Ajouter une image'}*/}
                            {/*                    </Upload>*/}
                            {/*                </ImgCrop>*/}
                            {/*            </div>*/}
                            {/*        </Row>*/}
                            {/*    </Row>*/}
                            {/*</Col>*/}

                            <Col style={{width: '70%'}}>
                                <Row style={{marginBottom: '15px'}}>
                                    <Col flex={1} style={{marginRight: '15px'}}>
                                        {/*<h3 style={{marginBottom: '10px'}}>Nom du produit</h3>*/}
                                        <Form.Item name="product_name" label={t('Nom du produit')} tooltip={t('Ce champ est requis')} {...config_name}>
                                            <Input placeholder={t('Donnez un nom à votre annonce')} showCount maxLength={256} allowClear={true}/>
                                        </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        {/*<h3 style={{marginBottom: '10px'}}>Description</h3>*/}
                                        <Form.Item name="description" label={t('Description')}
                                                   tooltip={t('Ce champ est requis')} {...config_desc}>
                                            <TextArea placeholder={t('Ecrivez une description pour donner plus d\'informations sur votre produit...')} rows={2} showCount maxLength={1024} allowClear={true}/>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col flex={1} style={{marginRight: '15px' , display : HiddenTVABouton ? 'none' : ''}}>
                                        {/*<h3 style={{marginBottom: '10px'}}>État</h3>*/}
                                            <Form.Item name="state" label={Text_tva_apply} tooltip={t('Ce champ est requis')} >
                                                {/*<Select*/}
                                                {/*    defaultValue={t('Sélectionner un état de la pièce')}*/}
                                                {/*    onChange={handleChangeSelect}*/}
                                                {/*    style={{width: '100%'}}*/}
                                                {/*    options={selectOptions}*/}
                                                {/*    id={"state"}*/}
                                                {/*/>*/}
                                                <Switch checked={TVAstate} onChange={handleSwitchChange} checkedChildren={<span>Oui</span>} unCheckedChildren={<span>Non</span>} />
                                            </Form.Item>
                                    </Col>
                                    <Col flex={1}>
                                        {/*<h3 style={{marginBottom: '10px'}}>Description de l'état de la pièce</h3>*/}
                                        <Form.Item name="state_description" label={t('Description de l\'état de la pièce')} tooltip={t('Ce champ est requis')} {...config_desc_state}>
                                            <TextArea placeholder={t('ex : pièce neuve sans rayures...')} rows={2} showCount maxLength={256} allowClear={true} />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <div>
                                    <Row style={{marginBottom: '15px'}}>
                                        <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                            {/*<h3 style={{marginBottom: '10px'}}>Prix de cession (HT)</h3>*/}
                                            <Form.Item name="price" label={t('Prix de vente (HT)')} tooltip={t('Ce champ est requis')} {...config_price}>
                                                <InputNumber placeholder={t('Indiquez le prix souhaité pour la vente de ce produit')} step={0.1} min={0} id="price_id"
                                                             style={{display: 'flex', width: '100%'}}
                                                             onChange={(value) => setPrice(TVAstate)} addonAfter="€" />
                                            </Form.Item>
                                        </Col>
                                        <Col flex={1}>
                                            {/*/*<h3 style={{marginBottom: '10px'}}>{t('Prix de cession (HT) en promotion')}</h3>*/}

                                                {/*<Form.Item name="price_promo" label={t('Prix de vente en promotion (HT) ')}  rules={[]} >*/}
                                                {/*    <InputNumber placeholder={t('Indiquez le prix promo souhaité pour la vente de ce produit')} step={0.1} min={0}*/}
                                                {/*                 id="price_promo_id"*/}
                                                {/*                 style={{display: 'flex', width: '100%'}}*/}
                                                {/*                 onChange={(value) => setPrice(TVAstate)} addonAfter="€" />*/}

                                                {/*</Form.Item>*/}

                                            <h3 style={{marginBottom: '10px'}}>{t('Prix de vente (TTC) ')} <span style={{fontSize: 'smaller',fontWeight: 'lighter'}}>{Text_commission_included}</span></h3>
                                            <Form.Item name="price_visualised">
                                                <InputNumber placeholder={t('Prix TTC affiché en ligne sur la marketplace')} step={0.1} min={0}
                                                             style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <Row style={{marginBottom: '15px'}}>
                                        {/*<Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>*/}
                                        {/*    <h3 style={{marginBottom: '10px'}}>{t('Prix de vente (TTC)')} <span style={{fontSize: 'smaller',fontWeight: 'lighter'}}>{t('commission de 15% incluse')}</span></h3>*/}
                                        {/*    <Form.Item name="price_visualised">*/}
                                        {/*        <InputNumber placeholder={t('Prix TTC affiché en ligne sur le site')} step={0.1} min={0}*/}
                                        {/*                     style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>*/}
                                        {/*    </Form.Item>*/}
                                        {/*</Col>*/}
                                        {/*<Col flex={1}>*/}
                                        {/*    <h3 style={{marginBottom: '10px'}}>{t('Prix en promotion mis en vente (TTC)')}</h3>*/}
                                        {/*    <Form.Item name="price_promo_visualised">*/}
                                        {/*        <InputNumber placeholder={t('Prix en promo TTC affiché en ligne sur le site')} step={0.1} min={0}*/}
                                        {/*                     style={{display: 'flex', width: '100%'}} disabled={true} addonAfter="€"/>*/}
                                        {/*    </Form.Item>*/}
                                        {/*</Col>*/}
                                    </Row>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                {/*<h3 style={{marginBottom: '10px'}}>Quantité</h3>*/}
                                <Form.Item name="quantity" label={t('Quantité')}
                                           tooltip={t('Ce champ est requis')} {...config_stock}>
                                    <InputNumber placeholder={'Quantité du produit en stock'} min={0} step={1} style={{width: '100%'}}
                                                 value={inputValue} onChange={handleInputChange}/>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                {/*<h3 style={{marginBottom: '10px'}}>Catégorie</h3>*/}
                                <Form.Item name="product_categories" label={t('Catégorie')}
                                           tooltip={t('Ce champ est requis')} {...config_category}>
                                    <Select
                                        mode="multiple"
                                        style={{width: '100%'}}
                                        placeholder={t('Sélectionner des catégories pour votre produit')}
                                        optionLabelProp="label">
                                        {options.map((option) => (
                                            <Option key={option.value} value={option.value} label={option.label}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <Row style={{marginBottom: '15px'}}>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{marginBottom: '10px'}}>{t('Référence Produit')}</h3>
                                <Form.Item name="product_sku">
                                    <Input placeholder={t('Référence du Produit')} showCount maxLength={64} allowClear={true}/>
                                </Form.Item>
                            </Col>
                            {/*<Col flex={1}>*/}
                            {/*    <h3 style={{marginBottom: '10px'}}>{t('Référence Vendeur')}</h3>*/}
                            {/*    <Form.Item name="vendor_sku">*/}
                            {/*        <Input placeholder={t('Référence Vendeur')}/>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}
                            <Col flex={1} style={{marginRight: '15px'}}>
                                {/*<h3 style={{marginBottom: '10px'}}>Marque</h3>*/}

                                <Form.Item name="marque" label={t('Marque')}>
                                    <Input placeholder={t('Marque de la pièce')} showCount maxLength={256} allowClear={true}/>

                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: '15px'}}>
                            <Col flex={1} style={{marginRight: '15px', display: 'inline-grid'}}>
                                <h3 style={{marginBottom: '10px'}}>{t('Dimensions du colis')}</h3>
                                <Form.Item name="depth" label={t('Longueur  (en centimètres)')}
                                           tooltip={t('Ce champ est requis')} {...config_longueur}>
                                    <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                </Form.Item>
                            </Col>
                            <Col flex={1} style={{marginRight: '15px'}}>
                                <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                <Form.Item name="width" label={t('Largeur (en centimètres)')}
                                           tooltip={t('Ce champ est requis')} {...config_largeur}>
                                    <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                </Form.Item>
                            </Col>
                            <Col flex={1}>
                                <h3 style={{visibility: 'hidden', marginBottom: '10px'}}>.</h3>
                                <Form.Item name="height" label={t('Hauteur (en centimètres)')}
                                           tooltip={t('Ce champ est requis')} {...config_hauteur}>
                                    <InputNumber placeholder={t('ex : 10')} step={0.01} min={0}
                                                 style={{display: 'flex', width: '100%'}} addonAfter="cm"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>

                            <Col flex={1} style={{ marginRight: '15px' }}>
                                <Row gutter={8} align="middle">
                                    <Col style={{marginTop: "-1%"}}>
                                        <Form.Item name="weight" label={unit_weight === 'kg' ? t('Poids du colis (en kg)') : t('Poids du colis (en grammes)')} tooltip={t('Ce champ est requis')}{...config_poids}>
                                            <InputNumber placeholder={unit_weight === 'kg' ? t('ex : 1') : t('ex : 1000')} step={0.01} min={0} />
                                            {/*<InputNumber placeholder={t('ex : 1000')} step={0.01} min={0} style={{width: '100%'}} addonAfter="g"/>*/}
                                            {/*<InputNumber placeholder={t('ex : 10')} step={0.01} min={0} style={{width: '100%'}} addonAfter="kg"/>*/}
                                        </Form.Item>
                                    </Col>
                                    <Col>
                                        <Select defaultValue={unit_weight} onChange={(value) => {setUnit_weight(value);}} >
                                            <Option value="kg">kg</Option>
                                            <Option value="g">g</Option>
                                        </Select>
                                    </Col>
                                </Row>
                            </Col>


                            <Col flex={1} style={{marginRight: '15px'}}>
                                {/*<h3 style={{marginBottom: '10px'}}>Conditionnement</h3>*/}
                                <Row style={{marginBottom: '20px'}}>
                                    <Form.Item name="conditioningType" label={t('Conditionnement')} style={{display:"none"}}
                                               tooltip={t('Ce champ est requis')} {...config_conditionning_type}>
                                        <Select
                                            defaultValue="unite"
                                            onChange={handleChangeSelect}
                                            style={{width: '100%'}}
                                            options={[
                                                {value: 'unite', label: t('Unité')},
                                                {value: 'millimetre', label: t('En millimètre')},
                                                {value: 'centimetre', label: t('En centimètre')},
                                                {value: 'metre', label: t('En mètre')},
                                                {value: 'lot', label: t('Lot')}
                                            ]}
                                        />
                                    </Form.Item>
                                </Row>
                            </Col>
                            <Col flex={1}>
                                {/*<h3 style={{marginBottom: '10px'}}>Valeur du conditionnement</h3>*/}
                                <Form.Item name="conditioningValue" label={t('Valeur du conditionnement')} style={{display:"none"}}
                                           tooltip={t('Ce champ est requis')} {...config_conditionning_value}>
                                    <Input placeholder={t('Valeur du conditionnement')} min={1} step={1} defaultValue="1"
                                           style={{width: '100%'}}/>
                                </Form.Item>
                            </Col>
                        </Row>
                        {/*<Row style={{marginBottom: '10px'}}>*/}
                        {/*    <Col flex={1}>*/}
                        {/*        <h3 style={{marginBottom: '10px'}}>Description additionnelle</h3>*/}
                        {/*        <Form.Item name="additional_description">*/}
                        {/*            <TextArea rows={2}/>*/}
                        {/*        </Form.Item>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row style={{justifyContent: 'start', marginBottom: '150px'}}>
                            <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                    htmlType="submit">{t('Créer le produit')}</Button>
                        </Row>
                    </div>
                </Form>

        </div>
    );
}
