import {
    Button,
    Col,
    Input,
    Row,
    Select,
    Space,
    Table,
    Pagination,
    Form,
    Upload,
    UploadProps,
    message,
    Spin,
    Progress,
    Card,
    Modal
} from "antd";
import React, {useEffect, useState} from "react";
import MediaQuery  from 'react-responsive';
import {EditOutlined, SearchOutlined, DeleteOutlined, InboxOutlined, UploadOutlined} from "@ant-design/icons";
import {
    deleteProduct,
    downloadProduct, getVendorProduct,
    GlobalProduct,telechargerFichierExemple
} from "../../Products/Products";
import {UploadFile} from "antd/es/upload/interface";
import {CreateProduct } from "../index";
import {DownloadProducts} from "./downloadProducts";
import {useTranslation} from "react-i18next";
import ModifyProduct from "./ModifyProduct";
import {deleteOrder} from "../../Order";




interface dataSourceInterface {
    key: string,
    id: string,
    productName: string,
    sku: string,
    stock: string,
    quantity: number
    price: number,
    promoPrice: number,
    // categories: string,
    date: string,
    slug: string,
}

export default function Products() {
    const {t} = useTranslation();

    const handleUploadChange = (info: any) => {
        if (info.file.status === 'done') {
            message.success(`${info.file.name} a été téléchargé avec succès`);
        } else if (info.file.status === 'error') {
            let errorMessage = "Une erreur est survenue lors du téléchargement";
            if (info.file.response && info.file.response.message) {
                errorMessage = info.file.response.message;
            }
            message.error(errorMessage, 10);
        }
    };
    const [fileList2, setFileList2] = useState<UploadFile[]>([]);
    const onChange2: UploadProps['onChange'] = ({fileList: newFileList2}) => {
        setFileList2(newFileList2);
    };
    const config = {
        // ContentType: 'multipart/form-data; boundary=---011000010111000001101001',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
    }

    const data = {
        name: "",
        description: ""
    }
    const [searchString, setSearchString] = useState("");
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"
    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    useEffect(() => {
        const fetchUserInfos = async () => {
            const products = await getVendorProduct();
            setProductInfos(products)
            console.log(products)
            products.reverse()
            if (products.length===0){
                console.log("No PRODUCT data found");
                let btn_telecharger_produits=document.getElementById("dl_products");
                let btn_edit_massif_products=document.getElementById("edit_massif_products");
                if (btn_telecharger_produits && btn_edit_massif_products){
                    btn_telecharger_produits.style.display="none";
                    btn_edit_massif_products.style.display="none";
                    console.log("deso pas deso , pas de produits pour toi")
                }
            }
            try {

                let mappedOptions: dataSourceInterface[] = [];

                for (const product of products){

                    mappedOptions.push({
                        key: product.product.id as unknown as string,
                        productName: t(product.product.product_name),
                        sku: product.product.product_sku,
                        date: new Date(product.product.creation_date * 1000).toDateString(),
                        // categories: '',
                        id: product.product.id as unknown as string,
                        price: product.product.price?.toFixed(2) as unknown as number,
                        promoPrice: product.product.price_promo?.toFixed(2) as unknown as number,
                        stock: product.product.quantity > 0 ? "Stock" : "Hors stock",
                        quantity: product.product.quantity,
                        slug : product.product.slug,
                    } as dataSourceInterface);

                }

                setOptions(mappedOptions);
            } catch (error) {
                console.log("No user data found",error);
            }
            setDataFetched(true);
        };
        if (!dataFetched) {
            fetchUserInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };

    const dataSource = options.filter(option =>
        option.productName?.toLowerCase().includes(searchString.toLowerCase()) ||
        option.sku?.toLowerCase().includes(searchString.toLowerCase())
    );

    const dataSource_mobile = options.filter(option =>
        option.productName?.toLowerCase().includes(searchString.toLowerCase()) ||
        option.sku?.toLowerCase().includes(searchString.toLowerCase())
    );


    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Nom du Produit'),
            dataIndex: 'productName',
            key: 'productName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('SKU'),
            dataIndex: 'sku',
            key: 'sku',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Quantité'),
            dataIndex: 'quantity',
            key: 'quantity',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Stock'),
            dataIndex: 'stock',
            key: 'stock',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.stock === 'Stock') {
                    return <span style={{
                        color: '#50CD89',
                        backgroundColor: '#DCF2E6',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{text}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },
        {
            title: t('Prix de Cession (HT)'),
            dataIndex: 'price',
            key: 'price',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Prix Promo de Cession (HT)'),
            dataIndex: 'promoPrice',
            key: 'promoPrice',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        // {
        //     title: 'Catégories',
        //     dataIndex: 'categories',
        //     key: 'categories',
        //     width: '15%',
        //     render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        // },
        {
            title: t('Date'),
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => {handleShowModifyProduct(record.slug)}}/>
                    <Button style={{margin: '5px'}} icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => {
                        showDeleteConfirmation(record.id)
                    }}/>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const [currentPage_mobile, setCurrentPage_mobile] = useState(1);
    const [itemsPerPage_mobile, setItemsPerPage_mobile] = useState(10);

    const totalItems_mobile = dataSource_mobile.length;
    const rangeStart_mobile = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd_mobile = Math.min(rangeStart_mobile + itemsPerPage_mobile - 1, totalItems_mobile);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };
    const handlePageChange_mobile = (page: any) => {
        setCurrentPage_mobile(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const handleItemsPerPageChange_mobile = (current: any, size: any) => {
        setItemsPerPage_mobile(size);
    };


    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        // Add your hover styles here, for example:
        backgroundColor: '#000000',
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const [showModifyProduct, setShowModifyProduct] = useState(false);
    const [showProduct, setShowProduct] = useState(true);
    const [slug, setSlug] = useState("");

    // Step 2: Function to toggle the state when the button is clicked
    const handleShowModifyProduct = (slug_var:string) => {
        setShowModifyProduct(!showModifyProduct);
        setSlug(slug_var)
        setShowProduct(false)
    };

    const showDeleteConfirmation = (productId: number) => {
        Modal.confirm({
            title: 'Confirmation',
            content: 'Êtes-vous sûr de vouloir supprimer ce produit ?',
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                deleteProduct(productId);
                setOptions(options.filter((prod) => Number(prod.id) !== productId))
            },
        });
    };

    return (
        <div>
            {showModifyProduct ?
                <><Button onClick={() => {setShowModifyProduct(false); setShowProduct(true)}}>Retourner sur vos produits</Button><ModifyProduct slug={slug}/></>
                : <></>}
            {dataFetched && showProduct ? (
                <div>
                    <MediaQuery maxWidth={1100}>
                            <div style={{color: colorHeaderText, backgroundColor: '#F3F5F6'}}>
                                <div style={{margin: '15px'}}>
                                    <h1 style={{fontSize: '24px'}}>Mes Produits</h1>
                                </div>
                                <div style={{
                                    backgroundColor: '#FFFFFF',
                                    border: '1px solid #FFFFFF',
                                    borderRadius: '5px',
                                    width: '95%',
                                    margin: '20px auto'
                                }}>
                                    <div style={{padding: '20px'}}>
                                        <Row gutter={[16, 16]}>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Button  style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={() => { telechargerFichierExemple(`https://${process.env.WEBSITE_URL}`+ "/uploads/Files/Fichier_Exemple_Import_massif_nouveaux_produits.xlsx", 'fichier_exemple_ajout_massif.xlsx');}}>
                                                    {t('Fichier exemple import massif de produits')}
                                                </Button>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Form>
                                                    <Upload
                                                        name="file"
                                                        action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                        // listType="text"
                                                        // fileList={fileList2}
                                                        // onChange={onChange2}
                                                        headers={config}
                                                        method={"POST"}
                                                        // onPreview={onPreview}
                                                        // data={data}
                                                        onChange={handleUploadChange}
                                                        maxCount={1}
                                                        style={{marginBottom: '10px', width: '95%'}}
                                                    >
                                                        <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>{t('Ajouter massivement des produits')}</Button>
                                                    </Upload>
                                                </Form>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} id="dl_products">
                                                <DownloadProducts/>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12} id="edit_massif_products">
                                                <Form>
                                                    <Upload
                                                        name="file"
                                                        action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                        // listType="text"
                                                        // fileList={fileList2}
                                                        // onChange={onChange2}
                                                        headers={config}
                                                        method={"PUT"}
                                                        // onPreview={onPreview}
                                                        // data={data}
                                                        onChange={handleUploadChange}
                                                        maxCount={1}
                                                        style={{marginBottom: '10px', width: '95%'}}
                                                    >
                                                        <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>{t('Modification massive des produits')}</Button>
                                                    </Upload>
                                                </Form>
                                            </Col>
                                            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                                                <Space.Compact style={{ width: '95%' }}>
                                                    <Input
                                                        name="search"
                                                        placeholder={t('Rechercher un produit...')}
                                                        style={{ width: '100%' }}
                                                        value={searchString}
                                                        onChange={onSearchInputChange}
                                                    />
                                                    <Button type="primary" style={{ color: colorHeaderText, backgroundColor: colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar }}>
                                                        <SearchOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>
                                                    </Button>
                                                </Space.Compact>
                                                <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                        onClick={() => document.getElementById("createProduct")?.click()}>
                                                    {t('Ajouter un produit')}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                    <div style={{marginTop: '10px'}}>
                                        <Row gutter={[16, 16]}>
                                            {dataSource_mobile.slice((currentPage_mobile - 1) * itemsPerPage_mobile, currentPage_mobile * itemsPerPage_mobile).map(product => (
                                                <Col key={product.id} xs={24} sm={12} md={8} lg={6} xl={4}>
                                                    <Card title={product.productName} extra={
                                                        <div>
                                                            <Button style={{ margin: '5px' }} icon={<EditOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => { handleShowModifyProduct(product.slug) }} />
                                                            <Button style={{ margin: '5px' }} icon={<DeleteOutlined  onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>} onClick={() => {
                                                                showDeleteConfirmation(Number(product.id))
                                                            }} />
                                                        </div>
                                                    }
                                                    >
                                                        {/* ID */}
                                                        <p>ID: <span style={{color: colorHeaderText}}>{product.id}</span></p>

                                                        {/* SKU */}
                                                        <p>{t('SKU')}: <span style={{color: colorHeaderText}}>{product.sku}</span></p>

                                                        {/* Quantité */}
                                                        <p>{t('Quantité')}: <span style={{color: colorHeaderText}}>{product.quantity}</span></p>

                                                        {/* Stock */}
                                                        <p>{t('Stock')}:
                                                            {product.stock === 'Stock' ?
                                                                <span style={{
                                                                    color: '#50CD89',
                                                                    backgroundColor: '#DCF2E6',
                                                                    padding: '5px',
                                                                    border: '1px solid #DCF2E6',
                                                                    borderRadius: '5px'
                                                                }}>{product.stock}</span> :
                                                                <span style={{
                                                                    color: '#F1416C',
                                                                    backgroundColor: '#FFF5F8',
                                                                    padding: '5px',
                                                                    border: '1px solid #FFF5F8',
                                                                    borderRadius: '5px'
                                                                }}>{product.stock}</span>
                                                            }
                                                        </p>

                                                        {/* Prix de Cession (HT) */}
                                                        <p>{t('Prix de Cession (HT)')}: <span style={{color: colorHeaderText}}>{product.price}</span></p>

                                                        {/* Prix Promo de Cession (HT) */}
                                                        <p>{t('Prix Promo de Cession (HT)')}: <span style={{color: colorHeaderText}}>{product.promoPrice}</span></p>

                                                        {/* Date */}
                                                        <p>{t('Date')}: <span style={{color: colorHeaderText}}>{product.date}</span></p>

                                                    </Card>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <div style={{margin: '20px'}}>
                                        <style>
                                            {`.ant-pagination-total-text{display : flex !important}`}
                                        </style>
                                        <Pagination total={totalItems_mobile} current={currentPage_mobile}
                                            showSizeChanger onShowSizeChange={handleItemsPerPageChange_mobile}
                                            onChange={handlePageChange_mobile}
                                            showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                            pageSize={itemsPerPage_mobile} defaultCurrent={1}
                                            style={{position: 'fixed', bottom: 0, width: '100%', background: 'white', padding: '10px', zIndex: 1,left:0}}/>
                                    </div>
                                </div>
                            </div>
                    </MediaQuery>
                    <MediaQuery minWidth={1101}>
                        <div style={{color: colorHeaderText, backgroundColor: '#F3F5F6'}}>
                            <div style={{margin: '15px'}}>
                                <h1 style={{fontSize: '24px'}}>{t('Mes Produits')}</h1>
                            </div>
                            <div style={{
                                backgroundColor: '#FFFFFF',
                                border: '1px solid #FFFFFF',
                                borderRadius: '5px',
                                width: '95%',
                                margin: '20px auto'
                            }}>
                                <div style={{padding: '20px'}}>
                                    <Row>
                                        <Col flex={2} style={{margin:'auto 0'}}>
                                            {/*<Space style={{borderWidth: 15,borderColor:'#fff',width:'55%'}}>*/}
                                            <Space.Compact style={{width: '75%'}}>
                                                <Input
                                                    name="search"
                                                    placeholder={t('Rechercher un produit...')}
                                                    value={searchString}
                                                    onChange={onSearchInputChange}
                                                />
                                                <Button type="primary" style={{
                                                    color: colorHeaderText, backgroundColor:
                                                    colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                                }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                            </Space.Compact>
                                            {/*</Space>*/}
                                            <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}}
                                                    onClick={() => document.getElementById("createProduct")?.click()}>
                                                {t('Ajouter un produit')}
                                            </Button>
                                        </Col>

                                        <Col flex={1}>
                                            <Button  style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={() => { telechargerFichierExemple(`https://${process.env.WEBSITE_URL}`+ "/uploads/Files/Fichier_Exemple_Import_massif_nouveaux_produits.xlsx", 'fichier_exemple_ajout_massif.xlsx');}}>
                                                {t('Fichier exemple import massif de produits')}
                                            </Button>
                                        </Col>
                                        <Col flex={1} style={{display: 'contents'}}>
                                            <Form>
                                                <Upload
                                                    name="file"
                                                    action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                    // listType="text"
                                                    // fileList={fileList2}
                                                    // onChange={onChange2}
                                                    headers={config}
                                                    method={"POST"}
                                                    // onPreview={onPreview}
                                                    // data={data}
                                                    onChange={handleUploadChange}
                                                    maxCount={1}
                                                    style={{marginBottom: '10px', width: '95%'}}
                                                >
                                                    <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>{t('Ajouter massivement des produits')}</Button>
                                                </Upload>
                                            </Form>
                                        </Col>

                                    </Row>
                                    <Row style={{marginTop:'10px'}}>
                                        <Col flex={1} style={{marginLeft:'50%'}} id="dl_products">
                                            {/*<Button id="dl_products" style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={() => {downloadProduct()}}>*/}
                                            {/*    Télécharger mes produits*/}
                                            {/*</Button>*/}
                                            <DownloadProducts/>
                                        </Col>
                                        {/*<Space style={{borderWidth: 15,borderColor:'#fff'}}>*/}
                                        <Col flex={1} style={{display: 'contents'}} id="edit_massif_products">
                                            <Form>
                                                <Upload
                                                    name="file"
                                                    action={`https://${process.env.REACT_APP_API_URL}` + "/upload/csv-products"}
                                                    // listType="text"
                                                    // fileList={fileList2}
                                                    // onChange={onChange2}
                                                    headers={config}
                                                    method={"PUT"}
                                                    // onPreview={onPreview}
                                                    // data={data}
                                                    onChange={handleUploadChange}
                                                    maxCount={1}
                                                    style={{marginBottom: '10px', width: '95%'}}
                                                >
                                                    <Button icon={<UploadOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}>{t('Modification massive des produits')}</Button>
                                                </Upload>
                                            </Form>
                                        </Col>
                                        {/*</Space>*/}

                                    </Row>
                                </div>
                                <div style={{marginTop: '10px'}}>
                                    <style>
                                        {`
                                  .custom-table tbody {
                                    height: 20px;
                                    overflow-y: auto;
                                  }
                                  
                                  /* Remove default padding on the table and related elements */
                                  .custom-table .ant-table-thead tr th,
                                  .custom-table .ant-table-tbody tr td {
                                    padding: 5px;
                                  }
                                `}
                                    </style>
                                    <Table
                                        dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                        columns={columns}
                                        bordered
                                        pagination={false}
                                        className="custom-table"
                                        style={{margin: '0 20px'}}
                                        scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                                    />
                                </div>
                                <div style={{margin: '20px'}}>
                                    <style>
                                        {`
                                  .customPaginationStyle {
                                     display: flex;
                                     justify-content: center;
                                     align-items: center;
                                  }
                                  
                                  .customPaginationStyle : hover {
                                     background-color : #000000
                                  }
                                `}
                                    </style>
                                    <Pagination
                                        total={totalItems}
                                        current={currentPage}
                                        showSizeChanger
                                        onShowSizeChange={handleItemsPerPageChange}
                                        onChange={handlePageChange}
                                        showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} produits`}
                                        pageSize={itemsPerPage}
                                        defaultCurrent={1}
                                        style={customPaginationStyle}
                                    />
                                </div>
                            </div>
                        </div>
                    </MediaQuery>
                </div>
            ) : showProduct ? (
                <div>
                    <div style={{ margin: '15px' }}>
                        <h1 style={{ fontSize: '24px' }}>{t('Mes Produits')}</h1>
                        <div style={{ margin: 'auto', textAlign: 'center', marginTop: '10%' }}>
                            <span style={{ marginRight: '10%' }}>{t('Chargement de vos produits en cours')}</span>
                            <Spin size="large" />
                        </div>
                    </div>
                </div>
            )
                : <></>
            }

        </div>
    );
}