import React, {useEffect, useRef, useState} from "react";
import fetchAPI from "../Fetch";
import "../Media/Gallery.css"
import ReactModal from "react-modal";
import ReactPaginate from "react-paginate";
import {Badge, Button, Input, Modal, Spin} from "antd";
import {UserInterface} from "../User";
import {useTranslation} from "react-i18next";

export interface Medias {
    id: number;
    url: string;
    creation_date: number;
    modification_date: number;
    user_id: number;
    description: string;
    name: string;
    active: boolean;
}

interface GalleryProps {
    onSelect: (selectedImage: Medias[]) => void;
    preSelectedImages: Medias[] | null;
}

function Media(media_id: number): Promise<string> {
    const handleMedia = async () => {
        const get_media = await fetchAPI(
            `media/public/product/` + media_id,
            "GET",
            false,
            false
        );
        if (get_media.status === 400) {
            return "";
        } else {
            if (get_media.url && (get_media.url as string)) {
                return get_media.url as string;
            } else {
                return "";
            }
        }
    };
    return handleMedia();
}

async function createUserExport(file_type:string, user_type:1|0){
    const reponse = await fetchAPI("upload/exportUsers/xlsx", "POST", true, true, {
        "user_type":user_type
    })
    console.log("user files xlsx: ", reponse)
    if (reponse.status === 400) {
        console.log(reponse)
    }
    return reponse.response
}



async function getInvoiceByOrderId(order_id:number){
    const reponse = await fetchAPI("media/invoiceByOrderId/" + order_id, "GET", true, true)
    if (reponse.status === 400) {
        console.log(reponse)
    }
    return reponse.response as Medias[]
}
async function getChronoLabelByOrderId(order_id:number){
    const reponse = await fetchAPI("media/chonoLabelByOrderId/" + order_id, "GET", true, true)
    if (reponse.status === 400) {
        console.log(reponse)
    }
    return reponse.response as Medias[]
}
async function getMediaInfos(media_id: number, vendor_id:number|undefined=undefined): Promise<Medias> {
    let response
    if(vendor_id){
        response = await fetchAPI("media/imageInfos/" + media_id, "POST", true, true,{
            vendor_id:vendor_id
        })
    } else {
        response = await fetchAPI("media/imageInfos/" + media_id, "POST", true, true)
    }

    if (response.status === 400) {
        console.log("error",response)
    }
    return response.response as Medias
}

async function uploadMedia(file: File) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('description', "Ajout d'une image");
    formData.append('name', file.name);

    return await fetch(`https://${process.env.REACT_APP_API_URL}`+ '/media/create', {
        method: 'POST',
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
        body: formData,
    })
        .then(response => response.json())
        .then(data => {
            console.log(data)
            return data.response.media
            //console.log('Image uploaded successfully:', data);
            // Optionally, you can update the media list or take other actions
        })
        .catch(error => console.error('Error uploading image:', error));
}

async function getUserMedia(
    maxQuery: number,
    pageQuery: number
): Promise<Array<Medias>> {
    const medias = await fetchAPI(
        `media/allPictures/?max=` + maxQuery + "&page=" + pageQuery,
        "GET",
        true,
        true
    );
    return medias.response as Medias[];
}

async function removeMedia(media_id: number) {
    const result = await fetchAPI("media/" + media_id + "/remove", "GET", true, true)
    return result.status
}

async function getUserMediaLength(): Promise<number> {
    const medias = await fetchAPI(
        `media/allPicturesNumber/`,
        "GET",
        true,
        true
    );
    return medias.response as number;
}

const Gallery: React.FC<GalleryProps> = ({onSelect, preSelectedImages = null}) => {
    const {t} = useTranslation();

    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredMediaList, setFilteredMediaList] = useState<Medias[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [mediaLength, setMediaLength] = useState(1);
    const [dataFetched, setDataFetched] = useState(false);
    const pageSize = 6; // Nombre d'images par page
    const [mediaList, setMediaList] = useState<Medias[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [selectedImages, setSelectedImages] = useState<Medias[]>([]);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [preselected, setPreselected] = useState(false);
    const validImageTypes = ['image/png', 'image/jpeg', 'image/jpg'];
    const maxFileSize = 10 * 1024 * 1024; // 6 Mo en octets

    let fileInputRef = useRef<HTMLInputElement | null>(null);

    const toggleSelectImage = (image: Medias) => {
        if (selectedImages.some((selectedImage) => selectedImage.id === image.id)) {
            // Image is already selected, remove it from the selection
            setSelectedImages((prevSelectedImages) =>
                prevSelectedImages.filter((selectedImage) => selectedImage.id !== image.id)
            );
        } else {
            // Check if the maximum limit of 5 images is reached
            if (selectedImages.length < 4) {
                // Image is not selected, add it to the selection
                setSelectedImages((prevSelectedImages) => [...prevSelectedImages, image]);
            } else {
                // Inform the user about the maximum limit
                alert('You can select a maximum of 4 images.');
            }
        }
    };
    const handleFinishSelection = () => {
        // Pass the selected images to the parent component or perform any other action
        // For now, simply alert the selected images
        onSelect(selectedImages);
        closeModal()
    };
    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        // Additional styling or feedback for the dragged-over area
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        setIsDragging(false);

        const droppedFiles = e.dataTransfer.files;
        handleDroppedFiles(droppedFiles);
    };

    const openModal = () => {
        setModalIsOpen(true);
        setCurrentPage(1); // Réinitialiser la page lorsque la modal s'ouvre
    };

    const closeModal = () => {
        setModalIsOpen(false);
        setSearchQuery(''); // Réinitialiser la recherche lorsque la modal se ferme
    };

    useEffect(() => {
        const filteredList = mediaList.filter((media) =>
            media.name.toLowerCase().includes(searchQuery.toLowerCase())
        );
        setFilteredMediaList(filteredList);
    }, [searchQuery, mediaList]);

    useEffect(() => {
        setCurrentPage(1);
    }, [searchQuery]);

    useEffect(() => {
        if (preSelectedImages && !preselected) {
            console.log(preSelectedImages)
            preSelectedImages.forEach(media => {
                toggleSelectImage(media)
            })
            setPreselected(true)
        }
    }, [preSelectedImages,toggleSelectImage, preselected, setPreselected]);

    useEffect(() => {
        async function fetchData() {
            try {
                const mediaData = await getUserMedia(pageSize, currentPage);
                const mediaLength = await getUserMediaLength();
                setMediaList(mediaData);
                setMediaLength(mediaLength);
            } catch (error) {
                console.error("Error fetching media:", error);
            }
            setDataFetched(true)
        }

        if (!dataFetched) {
            fetchData();

        }
    }, [currentPage]);
    const handleDroppedFiles = (files: FileList) => {
        setIsLoading(true);

        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            console.log(file)
            if (!validImageTypes.includes(file.type)  || file.type === 'image/heif') {
                // Si le type de fichier n'est pas autorisé, gérer l'erreur ici
                alert('Type de fichier non valide. Seuls les fichiers PNG, JPG et JPEG sont autorisés.');
                setIsLoading(false);
                return;
            }

            if (file.size > maxFileSize) {
                // Si la taille du fichier dépasse la limite, gérer l'erreur ici
                alert('La taille du fichier dépasse la limite autorisée (6 Mo).');
                setIsLoading(false);
                return;
            }
            if (selectedFile) {
                uploadMedia(file).then((media) => {
                    console.log(media)
                    setTimeout(() => {
                        const newMedia: Medias = {
                            id: media.id,
                            url: media.url,
                            creation_date: media.creation_date,
                            modification_date: media.modification_date,
                            user_id: media.user_id,
                            description: media.description,
                            name: file.name,
                            active: true,
                        };
                        setMediaList([newMedia, ...mediaList]);
                    }, 5000);

                })
                setSelectedFile(null); // Reset the selected file
            }
            // Perform actions with the file, e.g., upload it
            else {
                uploadMedia(file).then((media) => {
                    console.log(media)
                    setTimeout(() => {
                        const newMedia: Medias = {
                            id: media.id,
                            url: media.url,
                            creation_date: media.creation_date,
                            modification_date: media.modification_date,
                            user_id: media.user_id,
                            description: media.description,
                            name: file.name,
                            active: true,
                        };
                        setMediaList([newMedia, ...mediaList]);
                        setIsLoading(false);
                    }, 5000);

                })
            }

        }
    };


    const handlePageClick = (selectedPage: { selected: number }) => {
        handlePageChange(selectedPage.selected + 1);
    };


    const handlePageChange = async (newPage: number) => {
        try {
            const newMediaData = await getUserMedia(pageSize, newPage);

            // Reset the page to 1 if a new search is performed
            if (searchQuery) {
                setMediaList(newMediaData);
            } else {
                setMediaList([...mediaList, ...newMediaData]);
            }

            // Update the current page
            setCurrentPage(newPage);
        } catch (error) {
            console.error("Error fetching media:", error);
        }
    };


    const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
    const [deleteImageId, setDeleteImageId] = useState<number | null>(null);

    const handleDeleteImage = async (mediaId: number) => {
        // Set the image id to be deleted and show the confirmation modal
        setDeleteImageId(mediaId);
        setDeleteConfirmationVisible(true);
    };

    const confirmDelete = async () => {
        // Close the confirmation modal
        setDeleteConfirmationVisible(false);

        try {
            if (deleteImageId) {
                const result = await removeMedia(deleteImageId)
                if (result === 200) {
                    setMediaList(mediaList.filter((media) => media.id !== deleteImageId))
                }
            }
        } catch (error) {
            console.error('Error deleting image:', error);
        }

        // Reset the delete image id
        setDeleteImageId(null);
    };

    const cancelDelete = () => {
        // Close the confirmation modal and reset the delete image id
        setDeleteConfirmationVisible(false);
        setDeleteImageId(null);
    };

    return (
        <div
            onDragEnter={handleDragEnter}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={isDragging ? "drag-over" : ""}>
            <Button onClick={openModal}>{t('Choisir des images depuis la Galerie')}</Button>

            <ReactModal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Media Gallery" overlayClassName="modal-overlay" className="modal-content">
                {/* Barre de recherche */}
                <div style={{borderRadius: "1px"}}>
                    <input
                        type="text"
                        placeholder={t('Rechercher')}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="search-input"
                    />
                </div>
                <div style={{marginBottom: "15px"}}>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => e.target.files ? handleDroppedFiles(e.target.files) : ""}
                        style={{display: 'none'}}
                        ref={(input) => (fileInputRef.current = input)}
                    />
                    <Button onClick={() => fileInputRef.current?.click()}>{t('Télécharger une nouvelle image')}</Button>
                </div>
                {/* Finish selection button */}
                <div className="finish-selection-button">
                    <Button
                        type="primary"
                        onClick={handleFinishSelection}
                        disabled={selectedImages.length === 0}
                    >
                        {t('Sélectionner ces photos')}
                        <Badge
                            count={selectedImages.length}
                            style={{backgroundColor: "#52c41a", marginLeft: "8px"}}
                        />
                    </Button>
                </div>
                {/* Contenu de la galerie */}
                <div className="gallery-container">
                    {isLoading && <Spin size="large" className="loading-spinner"/>}
                    {filteredMediaList
                        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
                        .map((media) => (
                            <div
                                key={media.id}
                                className={`gallery-item ${selectedImages.some((selectedImage) => selectedImage.id === media.id) ? 'selected' : ''}`}
                            >
                                <img
                                    src={`https://${process.env.REACT_APP_API_URL}`+ `/media/public/products/${media.url}`}
                                    alt={media.name}
                                    onClick={() => toggleSelectImage(media)}
                                    className="gallery-image"
                                />
                                {/* Checkmark for selected images */}
                                {selectedImages.some((selectedImage) => selectedImage.id === media.id) && (
                                    <div className="selection-overlay">
                                        <span>&#10003;</span>
                                    </div>
                                )}
                                {/* Delete button */}
                                {/*<Button onClick={() => handleDeleteImage(media.id)}>{t('Supprimer')}</Button>*/}
                            </div>
                        ))}
                </div>

                {/* Pagination */}
                <div className="pagination">
                    <ReactPaginate
                        previousLabel={t('Précédent')}
                        nextLabel={t('Suivant')}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={Math.ceil(mediaLength / pageSize)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={'pagination'}
                        activeClassName={'active'}
                    />
                </div>

               
            </ReactModal>

            {/* Delete confirmation modal */}
            <Modal
                title="Confirmation de suppression"
                visible={deleteConfirmationVisible}
                onOk={confirmDelete}
                onCancel={cancelDelete}
            >
                <p>{t('Êtes - vous sûr de vouloir supprimer cette image?')}</p>
            </Modal>
        </div>
    );
};


export {uploadMedia, Gallery, getUserMedia, Media, getUserMediaLength, getMediaInfos, getInvoiceByOrderId, createUserExport, getChronoLabelByOrderId};
