import {Button, Col, Input, Row, Space, Table, Pagination, Modal, Tooltip} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined, FileOutlined, WarningOutlined} from "@ant-design/icons";
import {getUserById, UserInterface} from "../../User";
import {
    getProductByID
} from "../../Products/Products";
import {
    changeOrderState, changeOrdersToEnded,
    createChronoLabel,
    deleteOrder,
    getAllOrders, getAllOrdersByModificationDate,
    Order,
    sendMailWithInvoiceToVendors
} from "../../Order";
import {getExpeditionPriceById} from "../../Expedition";
import {useTranslation} from "react-i18next";
import {getInvoiceByOrderId, getChronoLabelByOrderId} from "../../Media";
import {getTransactionDetails} from "../../MangoPay";
import {red} from "@mui/material/colors";
import {color} from "chart.js/helpers";
import TransactionView from "./TransactionView";

interface dataSourceInterface {
    key: string,
    id: string,
    buyerName: string,
    create_date: string,
    update_date: string,
    state: string,
    buyerEmail: string,
    vendorName: string
    priceTotal: number,
    productName: string,
    invoice: string[] | undefined,
    chrono: string[] | undefined,
    details: string | null,
    vendorId: number[],
    order_invoices_generated: boolean
}

export default function CommandsAdmin() {
    const {t} = useTranslation();
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [ordersInfos, setOrdersInfos] = useState({} as Order[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);
    const [searchString, setSearchString] = useState("");

    const onSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value);
    };


// Fonction de remplacement des noms des vendeurs
    function replaceVendorName(vendorNames: string): string {
        const replacements: { [key: string]: string } = {
            "Xavier CADOR, ": "Fairbanks, ",
            "Thomas GAUTIER, ": "VDM, ",
            "Christophe Soares, ": "Navicom, ",
            "vendeur vendeur,": "Cap'tain Chercheur, "
        };

        let replacedName = vendorNames;
        for (const [key, value] of Object.entries(replacements)) {
            replacedName = replacedName.replace(new RegExp(key, 'g'), value);
        }

        return replacedName;
    }

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            // const orders = await getAllOrders();
            if (windowWidth > 1100) {
                const orders = await getAllOrdersByModificationDate();
                setOrdersInfos(orders)
                setDataFetched(true);
                let newOption: dataSourceInterface;
                try {
                    let mappedOptions: dataSourceInterface[] = [];
                    for (const order of orders) {
                        const buyerName = await getUserById(order.buyer_id);
                        if (buyerName) {
                            let vendorName = {} as UserInterface
                            let vendorNames = ""
                            let productName = ""
                            let expeditionPrice = 0
                            let priceTotal = 0;

                            let have_vdm_backorder_product = false
                            const vendor_ids = []
                            for (const subOrder of order.sub_orders) {
                                const product = await getProductByID(subOrder.product_id)
                                productName = product.product_name
                                if (product.backorder && product.vendor_id === 2432) {
                                    console.log("vdm avec backorder pour cette commande : ", order.id)
                                    have_vdm_backorder_product = true
                                }
                                const vendorinfo = await getUserById(product.vendor_id)
                                if (vendorinfo && !vendorNames.includes(vendorinfo.firstname + " " + vendorinfo.lastname)) {
                                    vendorNames += vendorinfo.firstname + " " + vendorinfo.lastname + ", "
                                }
                                vendor_ids.push(product.vendor_id)
                                priceTotal += subOrder.product_price_TTC
                                expeditionPrice = await getExpeditionPriceById(subOrder.expedition_id) * 1.2
                            }
                            if (order.promotion) {
                                priceTotal = (priceTotal) * (1 - (order.promotion.value / 100))
                            }
                            if (order.sub_orders[0].state_id !== 7) {
                                let order_invoices_generated = false

                                let invoice = [""]
                                const invoice_result = await getInvoiceByOrderId(order.id)
                                if (invoice_result.length > 0) {
                                    invoice_result.forEach(invoice_res => {
                                        invoice.push("https://beta.captainchercheur.com/media/public/invoice/" + invoice_res.id)
                                    })
                                }
                                let chrono = [""]

                                const chrono_result = await getChronoLabelByOrderId(order.id)
                                if (chrono_result.length > 0) {
                                    chrono_result.forEach(chrono_res => {
                                        chrono.push("https://beta.captainchercheur.com/media/public/pdf/" + chrono_res.id)
                                    })
                                }

                                if (invoice_result.length > 0 && chrono_result.length > 0) {
                                    order_invoices_generated = true
                                }

                                let order_with_vdm_backorder_product = "/no"
                                if (have_vdm_backorder_product) {
                                    order_with_vdm_backorder_product = "/yes"
                                }

                                newOption = {
                                    key: order.id as unknown as string,
                                    id: order.id as unknown as string + order_with_vdm_backorder_product as unknown as string,
                                    buyerName: buyerName.firstname + " " + buyerName.lastname,
                                    buyerEmail: buyerName.user_email,
                                    create_date: new Date(order.creation_date * 1000).toDateString(),
                                    update_date: new Date(order.modification_date * 1000).toDateString(),
                                    state: order.sub_orders[0].state_id as unknown as string,
                                    vendorName: replaceVendorName(vendorNames),
                                    productName: productName,
                                    priceTotal: (expeditionPrice + priceTotal).toFixed(2) as unknown as number,
                                    invoice: invoice,
                                    chrono: chrono,
                                    details: await getTransactionDetails(order.id).then(transaction => {
                                        return transaction
                                    }),
                                    vendorId: vendor_ids,
                                    order_invoices_generated: order_invoices_generated
                                };
                                console.log(newOption.details)

                                mappedOptions.push(newOption);

                                // Update the state immediately with the new option
                                setOptions(() => [...mappedOptions]);

                            }
                        }


                    }
                } catch (error) {
                    console.log(error, "No user data found");
                }
            }
        };

        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };
    const handleEndedOrders = () => {
        console.log(changeOrdersToEnded())
    }
    // const dataSource = options
    const dataSource = options.filter(option =>
        option.id.toString().includes(searchString) ||
        option.buyerName?.toLowerCase().includes(searchString?.toLowerCase()) ||
        option.buyerEmail?.toLowerCase().includes(searchString?.toLowerCase())
    );

    const columns = [
        {
            title: t('ID'),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => {
                const truc = text.split("/");
                const order_id = truc[0];
                const backorder_vdm = truc[1];
                if (backorder_vdm === "yes") {
                    return <>
                        <span style={{color: colorHeaderText}}>{order_id + " "}
                            <Tooltip
                                title={"Appeler le vendeur pour avoir les délais de livraison car cette commande contient un produit de vdm avec un backorder"}>
                                <WarningOutlined style={{color: 'red'}} onPointerEnterCapture={undefined}
                                                 onPointerLeaveCapture={undefined}/>
                            </Tooltip>
                        </span>
                    </>
                } else {
                    return <span style={{color: colorHeaderText}}>{order_id}</span>
                }
            }
        },
        {
            title: t('Nom du client'),
            dataIndex: 'buyerName',
            key: 'buyerName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Email du client'),
            dataIndex: 'buyerEmail',
            key: 'buyerEmail',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Nom du vendeur'),
            dataIndex: 'vendorName',
            key: 'vendorName',
            width: '15%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Date de création'),
            dataIndex: 'create_date',
            key: 'create_date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.create_date);
                const dateB = new Date(b.create_date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => {
                const date = new Date(text);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');
                const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                return <span style={{color: colorHeaderText}}>{formattedDate}</span>;
            }
        },
        {
            title: t('Date de modification'),
            dataIndex: 'update_date',
            key: 'update_date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.update_date);
                const dateB = new Date(b.update_date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => {
                const date = new Date(text);
                const day = date.getDate().toString().padStart(2, '0');
                const month = (date.getMonth() + 1).toString().padStart(2, '0');
                const year = date.getFullYear();
                const hours = date.getHours().toString().padStart(2, '0');
                const minutes = date.getMinutes().toString().padStart(2, '0');
                const seconds = date.getSeconds().toString().padStart(2, '0');
                const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
                return <span style={{color: colorHeaderText}}>{formattedDate}</span>;
            }
        },
        {
            title: t('Status'),
            dataIndex: 'state',
            key: 'state',
            width: '20%',
            render: (text: any, record: any) => {
                if (record.state === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{t('En attente de paiement')}</span>;
                } else if (record.state === 2) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement réussi')}</span>;
                } else if (record.state === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>En cours de préparation</span>;
                } else if (record.state === 4) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Terminée')}</span>;
                } else if (record.state === 5) {
                    return <span style={{
                        color: '#f1b041',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Remboursée')}</span>;
                } else if (record.state === 6) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Annulée')}</span>;
                } else if (record.state === 7) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Supprimée')}</span>;
                } else if (record.state === 8) {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>
                        <Tooltip title={record.details}>
                          <span>Paiement refusé</span>
                        </Tooltip>
                    </span>;
                } else if (record.state === 9) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>
                        <Tooltip title={record.details}>
                          <span>En cours de livraison</span>
                        </Tooltip>
                    </span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },

        {
            title: 'Total',
            dataIndex: 'priceTotal',
            key: 'priceTotal',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '50%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button
                        style={{margin: '5px'}}
                        icon={<EditOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        onClick={() => {
                            window.open(`https://${process.env.WEBSITE_URL}` + "/uniqueOrder/" + record.key);
                        }}
                    />
                    <Button
                        style={{margin: '5px'}}
                        icon={<DeleteOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        onClick={() => showDeleteConfirmation(record.key)}
                    />
                    <Button
                        style={{margin: '5px'}}
                        icon={<FileOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        onClick={() => changeOrderState(record.key, record.vendorId)}
                    >Générer facture</Button>
                    <Button
                        style={{margin: '5px'}}
                        icon={<FileOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/>}
                        onClick={() => createChronoLabel(record.key, record.vendorId)}
                    >Générer chronolabel</Button>

                    {record.order_invoices_generated ? (

                            <Button style={{margin: '5px'}} icon={<FileOutlined onPointerEnterCapture={undefined}
                                                                                onPointerLeaveCapture={undefined}/>}
                                    onClick={() => sendMailWithInvoiceToVendors(record.key)}>Mail Vendors Invoices
                            </Button>
                        ) :
                        <></>
                    }
                </div>
            ),
        },
        {
            title: 'Facture',
            key: 'invoice',
            width: '10%',
            render: (text: any, record: any) => (
                record.invoice.length >= 1 ? (
                    <>
                        {record.invoice.map((invoice: string | undefined, index: number) => (
                            invoice ?
                                <Button
                                    key={index}
                                    style={{margin: '5px'}}
                                    icon={<FileOutlined onPointerEnterCapture={undefined}
                                                        onPointerLeaveCapture={undefined}/>}
                                    onClick={() => window.open(invoice)}
                                /> : <></>
                        ))}
                    </>
                ) : null
            )
        },
        {
            title: 'ChronoLabel',
            key: 'chrono',
            width: '10%',
            render: (text: any, record: any) => (
                record.chrono.length >= 1 ? (
                    <>
                        {record.chrono.map((chrono: string | undefined, index: number) => (
                            chrono ?
                                <Button
                                    key={index}
                                    style={{margin: '5px'}}
                                    icon={<FileOutlined onPointerEnterCapture={undefined}
                                                        onPointerLeaveCapture={undefined}/>}
                                    onClick={() => window.open(chrono)}
                                /> : <></>
                        ))}
                    </>
                ) : null
            )
        }
    ];

    const showDeleteConfirmation = (orderId: number) => {
        Modal.confirm({
            title: 'Confirmation',
            content: 'Êtes-vous sûr de vouloir supprimer cet enregistrement ?',
            okText: 'Oui',
            okType: 'danger',
            cancelText: 'Non',
            onOk() {
                // Appel à la fonction de suppression ici
                deleteOrder(orderId).then((value: any) => {
                    window.location.reload();
                });
            },
        });
    };
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        backgroundColor: '#000000',
    };

    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add the event listener when the component mounts
        window.addEventListener('resize', handleResize);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    const handleDownload = () => {
        // Assuming fileUrl is the URL of the file you want to download
        const fileUrl = 'https://beta.captainchercheur.com/upload/exportMonthOrders';
        // Create a temporary anchor element
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.download = 'filename'; // Change 'filename' to your desired file name
        anchor.click();
    };
    return (
        <div style={{color: colorHeaderText}}>

            {windowWidth < 1100 ? (
                <div>
                    <TransactionView/>

                </div>
            ) : (
                <div>
                    <div style={{margin: '15px'}}>
                        <h1 style={{fontSize: '24px'}}>Commandes</h1>

                        {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                        <Button type="primary" onClick={handleDownload}>
                            Télécharger toutes les commandes
                        </Button>
                        <Button type="primary" onClick={handleDownload}>
                            Télécharger le tableau
                        </Button>
                    </div>

                    <div style={{
                        marginTop: '20px',
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #FFFFFF',
                        borderRadius: '5px',
                        width: '100%',
                        margin: '20px auto'
                    }}>
                        <div style={{padding: '20px'}}>
                            <Row>
                                <Col flex={2}>
                                    <Space.Compact style={{width: '100%'}}>
                                        <Input
                                            name="search"
                                            placeholder="Rechercher un produit..."
                                            style={{width: '100%'}}
                                            value={searchString}
                                            onChange={onSearchInputChange}
                                        />
                                        <Button type="primary" style={{
                                            color: colorHeaderText, backgroundColor:
                                            colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                        }}><SearchOutlined onPointerEnterCapture={undefined}
                                                           onPointerLeaveCapture={undefined}/></Button>
                                    </Space.Compact>
                                </Col>
                                {/*<Col flex={2}>*/}
                                {/*    <Select*/}
                                {/*        showSearch*/}
                                {/*        placeholder="Statut"*/}
                                {/*        optionFilterProp="children"*/}
                                {/*        style={{width: '95%'}}*/}
                                {/*        onChange={onChange}*/}
                                {/*        onSearch={onSearch}*/}
                                {/*        filterOption={(input, option) =>*/}
                                {/*            (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())*/}
                                {/*        }*/}
                                {/*        options={[*/}
                                {/*            {*/}
                                {/*                value: '1',*/}
                                {/*                label: 'Statut 1',*/}
                                {/*                name: 'status1'*/}
                                {/*            },*/}
                                {/*            {*/}
                                {/*                value: '2',*/}
                                {/*                label: 'Statut 2',*/}
                                {/*                name: 'status2'*/}
                                {/*            },*/}
                                {/*            {*/}
                                {/*                value: '3',*/}
                                {/*                label: 'Statut 3',*/}
                                {/*                name: 'status3'*/}
                                {/*            },*/}
                                {/*        ]}*/}
                                {/*    />*/}
                                {/*</Col>*/}
                                {/*<Col flex={1} style={{justifyContent: 'end', marginRight: '20px', display: 'flex'}}>*/}
                                {/*    <Button style={{backgroundColor: colorHearderSearch, color: colorHeaderText}}>*/}
                                {/*        Exporter*/}
                                {/*    </Button>*/}
                                {/*</Col>*/}
                                <Col flex={1} style={{display: 'contents'}}>
                                    <Button style={{backgroundColor: colorProductBtn, color: colorHeaderText}} onClick={handleEndedOrders}>
                                        Passer les commandes en terminées
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                        <div style={{marginTop: '10px'}}>
                            <style>
                                {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                            </style>
                            <Table
                                dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                                columns={columns}
                                bordered
                                pagination={false}
                                className="custom-table"
                                style={{margin: '0 20px'}}
                                scroll={{x: 2000, y: 500}} // Set the desired height here (e.g., 400px)
                            />
                        </div>
                        <div style={{margin: '20px'}}>
                            <style>
                                {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                            </style>
                            <Pagination
                                total={totalItems}
                                current={currentPage}
                                showSizeChanger
                                onShowSizeChange={handleItemsPerPageChange}
                                onChange={handlePageChange}
                                showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} commandes`}
                                pageSize={itemsPerPage}
                                defaultCurrent={1}
                                style={customPaginationStyle}
                            />
                        </div>
                    </div>
                </div>
            )
            }
        </div>
    );
}