import React, {useState, useEffect, useRef} from 'react';
import {Input, List, Card, Spin, Button} from 'antd';
import {useNavigate} from 'react-router-dom'; // If using React Router v6
import {getProductsWithSearch} from '../Products/Products';
import {SearchOutlined} from "@ant-design/icons";

const ProductSearch: React.FC = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [visible, setVisible] = useState<boolean>(false); // State to manage visibility of the dropdown
    const listRef = useRef<HTMLDivElement | null>(null);
    const navigate = useNavigate(); // Use navigate for programmatic navigation in React Router v6

    // Handler for input change
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        setVisible(true); // Show the results dropdown when input changes
    };

    // Function to perform the search
    const handleSearch = async (searchTerm: string) => {
        if (searchTerm.trim() === '') {
            setSearchResults([]);
            return;
        }
        setLoading(true);
        try {
            const results = await getProductsWithSearch(searchTerm, 10000, 1); // Adjust parameters as needed
            setSearchResults(results);
            console.log(results);
        } catch (error) {
            console.error("Error fetching search results:", error);
            setSearchResults([]);
        }
        setLoading(false);
    };

    // Debounce the search
    useEffect(() => {
        if (inputValue.trim() === '') {
            setSearchResults([]);
            setVisible(false);
            return;
        }

        const delayDebounceFn = setTimeout(() => {
            handleSearch(inputValue);
        }, 300); // Debounce delay

        return () => clearTimeout(delayDebounceFn);
    }, [inputValue]);

    // Handle clicks outside of the dropdown
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (listRef.current && !listRef.current.contains(event.target as Node)) {
                setVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Function to handle "Enter" key press
    const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            navigateToShop();
        }
    };

    // Function to navigate to the shop page
    const navigateToShop = () => {
        if (inputValue.trim() !== '') {
            window.location.href = `/shop?name=${encodeURIComponent(inputValue)}`;
        }
    };

    return (
        <div style={{position: 'relative', width: '100%'}}>
            <div style={{display: 'flex', backgroundColor: 'white'}}>
                <Input
                    placeholder="Rechercher un produit..."
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress} // Trigger on pressing Enter
                    style={{
                        fontSize: '17px',
                        borderRadius: '0px',
                        boxShadow: '0 2px 0 rgba(5, 145, 255, 0.1)',
                        height: '50px',
                        flex: 1,
                    }}
                />
                <Button
                    type="primary"
                    onClick={navigateToShop}
                    style={{
                        color: "white",
                        backgroundColor:  '#1d71b8',
                        height: '50px',
                        fontSize: '18px'
                    }}
                >
                    <SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
                </Button>
            </div>

            {inputValue.trim() !== '' && visible && (
                <>
                    {loading && <Spin style={{position: 'absolute', right: '10px', top: '10px'}}/>}
                    <div ref={listRef}>
                        <List
                            bordered
                            style={{
                                position: 'absolute',
                                top: '100%',
                                width: '100%',
                                maxHeight: '400px',
                                overflowY: 'auto',
                                backgroundColor: 'white',
                                zIndex: 1000,
                                padding: 0,
                                margin: 0,
                            }}
                            dataSource={searchResults}
                            renderItem={(item) => (
                                <List.Item key={item.product.id} style={{padding: '10px', margin: 0}}>
                                    <Card
                                        hoverable
                                        style={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            height: 'auto',
                                            padding: '10px',
                                        }}
                                        onClick={() => {
                                            window.location.href = "/product/" + item.product.slug
                                        }}
                                    >
                                        <img
                                            alt={item.product.product_name}
                                            src={`https://${process.env.REACT_APP_API_URL}` + "/media/public/product/" + item.product.product_image}
                                            style={{
                                                width: '100px',
                                                height: '100px',
                                                objectFit: 'cover',
                                                marginRight: '15px',
                                            }}
                                        />
                                        <div style={{flex: 1}}>
                                            <Card.Meta
                                                title={`${item.product.product_name}${item.product.product_sku ? " - " + item.product.product_sku : ""}`}
                                                description={
                                                    item.product.price_promo ? (
                                                        <div>
                                                            <span style={{
                                                                textDecoration: 'line-through',
                                                                marginRight: '8px'
                                                            }}>
                                                                {`${((item.product.price * (1 + (item.product.commission.value / 100))) * 1.2).toFixed(2)} €`}
                                                            </span>
                                                            <span style={{color: 'red'}}>
                                                                {`${((item.product.price_promo * (1 + (item.product.commission.value / 100))) * 1.2).toFixed(2)} €`}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        `${((item.product.price * (1 + (item.product.commission.value / 100))) * 1.2).toFixed(2)} €`
                                                    )
                                                }
                                                style={{margin: 0}}
                                            />
                                        </div>
                                    </Card>
                                </List.Item>
                            )}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default ProductSearch;
