import React, { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import Router from './Router';
import { HeaderView } from './components/Header';
import { Layout } from 'antd';
import { FooterView } from './components/Footer';
import Dashboard from './pages/Dashboard/Vendor';
import Logout from './components/Misc/Logout';
import Client from './pages/Dashboard/Client';
import Admin from './pages/Dashboard/Admin';
import LoginView from './pages/Connexion/login';
import { GoogleReviewsContainerDur } from './components/Reviews';
import KPIPage from "./components/Analytics/KPIPage";
import KPI from "./components/Analytics";
import NavbarMobile from "./components/NavbarMobile";
import { StatusBar, Style } from '@capacitor/status-bar';
import ClientMobile from "./pages/Dashboard/Client/indexMobile";
import DashboardMobile from "./pages/Dashboard/Vendor/indexMobile";
import AdminMobile from "./pages/Dashboard/Admin/indexMobile";
import CreateProductAdmin from "./components/Dashboard/Admin/CreateProduct";
import { Capacitor } from "@capacitor/core";
import { AppLauncher } from '@capacitor/app-launcher';
import { App as CapacitorApp } from '@capacitor/app';
import {Browser} from "@capacitor/browser";
import { PushNotifications } from '@capacitor/push-notifications';
import {SendUserGoogleID} from "./components/User";
import logo from "./logo.svg";
import logo2 from "./img/poseidon_logo_finish-04.webp";

const App: React.FC = () => {
    CapacitorApp.addListener('backButton', ({canGoBack}) => {
        if (!canGoBack) {
            CapacitorApp.exitApp();
        } else {
            window.history.back();
        }
    });
    useEffect(() => {
        const preloadImage = (url: string) => {
            const img = new Image();
            img.src = url;
        };

        preloadImage(logo);
        preloadImage(logo2);
    }, []);
    const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);



    const handleResize = () => {
        setWindowWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // Redirection logic
        if (windowWidth < 1100 && !window.location.href.includes('poseidonshop://')) {
            console.log(windowWidth)
            window.location.href = 'poseidonshop://com.captainchercheur.poseidonshop';
        }
    }, [windowWidth]);

    const isNative = Capacitor.isNativePlatform();
    useEffect(() => {
        const ga_session_id = localStorage.getItem("ga_session_id")
        const user_id = localStorage.getItem("user_id")
        if( ga_session_id && user_id){
            SendUserGoogleID(ga_session_id, parseInt(user_id))
        }
        if (isNative) {
            // Demander les permissions
            PushNotifications.requestPermissions().then(result => {
                if (result.receive === 'granted') {
                    // Enregistrer pour recevoir les notifications
                    PushNotifications.register();
                } else {
                    console.log("Les permissions ont été refusées.");
                }
            });

            // Écouter les événements de registration
            PushNotifications.addListener('registration', (token) => {
                console.log('Token de notification : ', token.value);

                // Envoyer le token au serveur
                sendTokenToServer(token.value);
            });

            // Écouter les événements de notifications reçues
            PushNotifications.addListener('pushNotificationReceived', (notification) => {
                console.log('Notification reçue : ', notification);
            });

            PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
                console.log('Action sur la notification : ', notification);
            });
            const setStatusBarColor = async () => {
                await StatusBar.setBackgroundColor({ color: '#09154C' }); // Change '#FF5733' to your desired color
                await StatusBar.setStyle({ style: Style.Dark }); // Use Style.Dark if you have a light background
            };

            setStatusBarColor();
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [isNative]);
    const sendTokenToServer = async (token: string) => {
        try {
            const userId = localStorage.getItem("user_id"); // Remplacez ceci par l'ID de l'utilisateur actuel
            if(userId){
                const response = await fetch(`https://${process.env.WEBSITE_URL}` + 'users/firebase/' + userId, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ token: token }),
                });

                if (!response.ok) {
                    throw new Error(`Erreur HTTP ! statut : ${response.status}`);
                }

                console.log('Token envoyé au serveur avec succès');
            } else {
                console.log('user not logged in')
            }

        } catch (error) {
            console.error('Erreur lors de l\'envoi du token au serveur : ', error);
        }
    };

    return (
        <I18nextProvider i18n={i18n}>

            {window.location.pathname === '/logout' ? (
                    <Logout />
                ) :
                window.location.pathname === '/kpi' || window.location.pathname === "/KPI" ? (
                    <KPI />
                ) : window.location.pathname !== '/dashboard' ? (
                    <>
                        <Layout>
                            <HeaderView />
                            <Router />
                            {(window.location.pathname !== '/register' && window.location.pathname !== '/login' && windowWidth > 1100 ) && <GoogleReviewsContainerDur />}
                            <FooterView />
                        </Layout>
                    </>
                ) : !localStorage.getItem('role') && window.location.pathname === '/dashboard' ? (
                    <>
                        <Layout>
                            <HeaderView />
                            <LoginView />
                            <FooterView />
                        </Layout>
                    </>
                ) : (
                        <>
                                {localStorage.getItem('role') === 'buyer' ? (
                                    windowWidth < 1100 ? <ClientMobile /> : <Client />
                                ) : localStorage.getItem('role') === 'vendor' ? (
                                    windowWidth < 1100 ? <DashboardMobile /> : <Dashboard />
                                ) : localStorage.getItem('role') === 'admin' ? (
                                    windowWidth < 1100 ? <AdminMobile /> : <Admin />
                                ) : (
                                    <></>
                                )}
                        </>
                    )


            }


            {windowWidth < 1100 ? (<NavbarMobile />): (<> </>)}

        </I18nextProvider>
    );
};

export default App;
