import {Button, Col, Input, Row, Select, Space, Table, Pagination, InputNumber} from "antd";
import React, {useEffect, useState} from "react";
import {EditOutlined, SearchOutlined, DeleteOutlined} from "@ant-design/icons";
import {getProductByID, getProductByID_numéro2, GlobalProduct} from "../../Products/Products";
import {getAllOrders, getVendorOrders} from "../../Order";
import {getUserById, UserInterface} from "../../User";
import {getExpeditionPriceById} from "../../Expedition";
import {useTranslation} from "react-i18next";
import TransactionView from "../Admin/TransactionView";

interface dataSourceInterface {
    key: string,
    id: string,
    date: string,
    price: string,
    status: string
    productName: string,
    productSlug: string
}

export default function Commands() {
    const {t} = useTranslation();
    const colorHeaderVerticalBar = "#EAEAEA"
    const colorHeaderText = "#09154C"
    const colorHearderSearch = "rgba(0,0,0,0.0)"
    const colorProductBtn = "#7ED957"

    const [productInfos, setProductInfos] = useState({} as GlobalProduct[]);
    const [dataFetched, setDataFetched] = useState(false);
    const [options, setOptions] = useState<dataSourceInterface[]>([]);

    useEffect(() => {
        const fetchOrdersInfos = async () => {
            const orders = await getVendorOrders();
            setDataFetched(true);
            let newOption: dataSourceInterface;
            try {
                let mappedOptions: dataSourceInterface[] = [];
                for (const order of orders) {
                    let productName = ""
                    let priceTotal = 0;
                    for (const subOrder of order.sub_orders) {
                        const product = await getProductByID(subOrder.product_id)
                        if (product.vendor_id.toString() === localStorage.getItem("user_id")) {
                            productName += t(product.product_name) + " x" + subOrder.quantity + ", "
                            priceTotal += subOrder.product_price_TTC
                        }
                    }
                    if (order.sub_orders[0].state_id !== 6 && order.sub_orders[0].state_id !== 7 && order.sub_orders[0].state_id !== 8) {
                        newOption = {
                            key: order.id as unknown as string,
                            id: order.id as unknown as string,
                            date: new Date(order.creation_date * 1000).toDateString(),
                            status: order.sub_orders[0].state_id as unknown as string,
                            productSlug: "",
                            productName: productName,
                            price: priceTotal?.toFixed(2),
                        };
                        mappedOptions.push(newOption);
                        setOptions(() => [...mappedOptions]);
                    }
                }
            } catch (error) {
                console.log(error, "No user data found");
            }
        };
        if (!dataFetched) {
            fetchOrdersInfos();
        }
    }, [dataFetched]);

    const onChange = (value: string) => {
        console.log(`selected ${value}`);
    };

    const onSearch = (value: string) => {
        console.log('search:', value);
    };

    const dataSource = options

    const columns = [
        {
            title: t('Numéro de commande'),
            dataIndex: 'id',
            key: 'id',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        }, {
            title: t('Date de création'),
            dataIndex: 'date',
            key: 'date',
            width: '10%',
            sorter: (a: dataSourceInterface, b: dataSourceInterface) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            },
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        }, {
            title: t('Nom du/des produit(s)'),
            dataIndex: 'productName',
            key: 'productName',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: t('Status de la commande'),
            dataIndex: 'status',
            key: 'status',
            width: '15%',
            render: (text: any, record: any) => {
                if (record.status === 1) {
                    return <span style={{
                        color: '#00A3FF',
                        backgroundColor: '#F1FAFF',
                        padding: '5px',
                        border: '1px solid #DCF2E6',
                        borderRadius: '5px'
                    }}>{t('En attente de paiement')}</span>;
                } else if (record.status === 2) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Paiement réussi')}</span>;
                } else if (record.status === 3) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('En cours de préparation')}</span>;
                } else if (record.status === 4) {
                    return <span style={{
                        color: '#17c200',
                        backgroundColor: '#daffdb',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Terminée')}</span>;
                } else if (record.status === 5) {
                    return <span style={{
                        color: '#f1b041',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{t('Remboursé')}</span>;
                } else {
                    return <span style={{
                        color: '#F1416C',
                        backgroundColor: '#FFF5F8',
                        padding: '5px',
                        border: '1px solid #FFF5F8',
                        borderRadius: '5px'
                    }}>{text}</span>;
                }
            },
        },
        // {
        //     title: 'Nom du produit',
        //     dataIndex: 'productName',
        //     key: 'productName',
        //     width: '10%',
        //     render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        // },

        {
            title: t('Prix TOTAL TTC du produit commandé '),
            dataIndex: 'price',
            key: 'total',
            width: '10%',
            render: (text: any) => <span style={{color: colorHeaderText}}>{text}</span>
        },
        {
            title: 'Action',
            key: 'action',
            width: '10%',
            render: (text: any, record: any) => (
                <div style={{display: 'flex'}}>
                    <Button style={{margin: '5px'}} onClick={() => {
                        window.open(`https://${process.env.WEBSITE_URL}`+ "/uniqueordervendor/" + record.id)
                    }}>{t('Détails de la commande')}</Button>
                </div>
            )
        },
    ];


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const totalItems = dataSource.length;
    const rangeStart = (currentPage - 1) * itemsPerPage + 1;
    const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems);

    const handlePageChange = (page: any) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (current: any, size: any) => {
        setItemsPerPage(size);
    };

    const customPaginationStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const hoverStyle = {
        cursor: 'pointer',
        // Add your hover styles here, for example:
        backgroundColor: '#000000',
    };


    return (
        <div style={{color: colorHeaderText}}>
            <div>
                <div style={{margin: '15px'}}>
                    <h1 style={{fontSize: '24px'}}>{t('Mes Commandes')}</h1>
                    {/*<p style={{marginTop: '15px', fontSize: '16px'}}>Lorem ipsum dolor sit amet, consectetur.</p>*/}
                </div>
                <div style={{
                    marginTop: '20px',
                    backgroundColor: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    borderRadius: '5px',
                    width: '95%',
                    margin: '20px auto'
                }}>
                    <div style={{padding: '20px'}}>
                        <Row>
                            <Col flex={2}>
                                <Space.Compact style={{width: '95%'}}>
                                    <Input name='search' placeholder="Rechercher" style={{width: '100%'}}/>
                                    <Button type="primary" style={{
                                        color: colorHeaderText, backgroundColor:
                                        colorHearderSearch, border: '1px solid' + colorHeaderVerticalBar,
                                    }}><SearchOutlined onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}/></Button>
                                </Space.Compact>
                            </Col>
                            <Col flex={2}>
                                <Select
                                    showSearch
                                    placeholder="Statut"
                                    optionFilterProp="children"
                                    style={{width: '95%'}}
                                    onChange={onChange}
                                    onSearch={onSearch}
                                />
                            </Col>
                        </Row>
                    </div>
                    <div style={{marginTop: '10px'}}>
                        <style>
                            {`
                          .custom-table tbody {
                            height: 20px;
                            overflow-y: auto;
                          }
                          
                          /* Remove default padding on the table and related elements */
                          .custom-table .ant-table-thead tr th,
                          .custom-table .ant-table-tbody tr td {
                            padding: 5px;
                          }
                        `}
                        </style>
                        <Table
                            dataSource={dataSource.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)}
                            columns={columns}
                            bordered
                            pagination={false}
                            // className="custom-table"
                            style={{margin: '0 20px'}}
                            scroll={{y: 500}} // Set the desired height here (e.g., 400px)
                        />
                    </div>
                    <div style={{margin: '20px'}}>
                        <style>
                            {`
                          .customPaginationStyle {
                             display: flex;
                             justify-content: center;
                             align-items: center;
                          }
                          
                          .customPaginationStyle : hover {
                             background-color : #000000
                          }
                        `}
                        </style>
                        <Pagination
                            total={totalItems}
                            current={currentPage}
                            onChange={handlePageChange}
                            showTotal={(total, range) => `${range[0]}-${range[1]} sur ${total} commandes`}
                            pageSize={itemsPerPage}
                            defaultCurrent={1}
                            // style={customPaginationStyle}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}